import { downloadBlob } from '@/helpers/functions';

export default {
	namespaced: true,

	actions: {
		invoiceReceipt({ commit }, { storeId, invoiceId, timeZone }) {
			return axios.get(`/reports/invoices/receipt/${storeId}/${invoiceId}?html=true`, { params: { timeZone } })
				.then(({ data }) => data);
		},
		saleInvoice({ commit }, { storeId, invoiceId }) {
			return axios.get(`/reports/invoices/saleInvoice/${storeId}/${invoiceId}?html=false`, {
				responseType: 'blob'
			}).then(({ data }) => {
				downloadBlob(data, `Account-Statement ${new Date().toISOString().slice(0, 10)}.pdf`);
			});
		},
		delivery({ commit }, { storeId, invoiceId }) {
			return axios.get(`/reports/invoices/deliveryNote/${storeId}/${invoiceId}?html=false`, {
				responseType: 'blob'
			}).then(({ data }) => {
				downloadBlob(data, `Account-Statement ${new Date().toISOString().slice(0, 10)}.pdf`);
			});
		},
		receive({ commit }, { storeId, invoiceId }) {
			return axios.get(`/reports/invoices/receiptNote/${storeId}/${invoiceId}?html=false`, {
				responseType: 'blob'
			}).then(({ data }) => {
				downloadBlob(data, `Account-Statement ${new Date().toISOString().slice(0, 10)}.pdf`);
			});
		},
		accountStatement({ commit }, { storeId, accountId, fromDate, toDate }) {
			return axios.get(`/reports/accounting/accountStatement/${storeId}/${accountId}`, { 
				params: { fromDate, toDate },
				responseType: 'blob'
			}).then(({ data }) => {
				downloadBlob(data, `Account-Statement ${new Date().toISOString().slice(0, 10)}.pdf`);
			})
		},

		reportItemExcel({ commit }, { storeId }) {
			return axios.get(`/reports/Items/${storeId}/excel`)
		},

		detailedAccountStatement({ commit }, { storeId, accountId, fromDate, toDate, timeZone, isDownload }) {
			return axios.get(`/reports/accounting/DetailedAccountStatement/${storeId}/${accountId}`, {
				params: { fromDate, toDate, format: isDownload ? 'pdf' : 'html', timeZone },
				responseType: isDownload ? 'blob' : 'json'
			}).then(({ data }) => data);
		},

		accountsSales({ commit }, { storeId, itemId, fromDate, toDate, isDownload, timeZone }) {
			return axios.get(`/Reports/Items/Sales/${storeId}`, {
				params: { itemId, fromDate, toDate, format: isDownload ? 'pdf' : 'html', timeZone },
				responseType: isDownload ? 'blob' : 'json'
			}).then(({ data }) => data)
		},
		
		ItemsSalesForAccount({ commit }, { storeId, accountId, itemId, fromDate, toDate, timeZone, isDownload }) {
			return axios.get(`/Reports/Accounts/Sales/${storeId}/${accountId}`, {
				params: { itemId, fromDate, toDate, format: isDownload ? 'pdf' : 'html', timeZone },
				responseType: isDownload ? 'blob' : 'json'
			}).then(({ data }) => data)
		},

		totalMaterialsSales({ commit }, { storeId, itemId, fromDate, toDate, isDownload, timeZone }) {
			return axios.get(`/reports/items/sales/${storeId}/${itemId}`, {
				params: { itemId, fromDate, toDate, format: isDownload ? 'pdf' : 'html', timeZone },
				responseType: isDownload ? 'blob' : 'json'
			}).then(({ data }) => data);
		},

		vatStatement({ commit }, { storeId, fromDate, toDate, isDownload, isExcel, timeZone }) {
			return axios.get(`/Reports/Invoices/VatStatement/${storeId}`, { 
				params: { fromDate, toDate, format: isExcel ? 2 : (isDownload ? 1 : 0), timeZone },
				responseType: isDownload ? 'blob' : 'json'
			}).then(({ data }) => data)
		}
	}
}