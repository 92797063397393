export default {
	'user-create': 'Kullanıcı oluşturma',
	'user-edit': 'Kullanıcı düzenleme',
	'edit-account-status': 'Hesap durumunu düzenleme',
	'are-you-sure-to-deactivate-an-account': 'Bir hesabı devre dışı bırakacağınızdan emin misiniz',
	'are-you-sure-to-activate-an-account': 'Bir hesabı aktif edeceğinizden emin misiniz',
	'user-power': 'Kullanıcı yetkisi',
	'store-name': 'Depo adı',
	'power-type': 'Yetki türü',
	casher: 'Kasiyer',
	'executive-director': 'Depo müdürü',
	optional: 'İsteğe bağlı',
	'change-password': 'Şifre değiştirme',
	'password-has-been-modified-successfully': 'Şifre başarıyla değiştirildi',
	active: 'Aktif',
	stopped: 'Durdurulan',
	'user-info': 'Kullanıcı verileri',
	'the-user-has-been-added-successfully': 'Kullanıcı başarıyla eklendi',
	'user-data-has-been-modified-successfully': 'Kullanıcı verileri başarıyla değiştirildi',
	viewer: 'İzleyici'
}