import Vue from 'vue';
import { colors } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';
import ar from 'vuetify/lib/locale/ar.js';
import en from 'vuetify/lib/locale/en.js';
import tr from 'vuetify/lib/locale/tr.js';
import i18n from '../i18n/i18n'
// import i18n from './locales/i18n'

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#02855b',
				bgColor: '#f5f5f7',
				secondary: '#a7a7a7',
				success: '#13a689',
				error: '#ff3334',
				errorLight: '#eeb4b3',
				'alert-color': '#e94747',
				'edit-color': '#dd8b1b',
				main: colors.grey.darken3,
				darkGreen: colors.green.darken1,
				'blue-light': colors.blue.darken1,
				'blue-dark': colors.blue.darken4,
				'of-white': '#f2f2f2',
				'dark-blue-primary': '#001e66'
			}
		},
	},
	rtl: i18n.locale === 'ar',
	lang: {
		locales: { en, ar, tr },
		current: i18n.locale
	},
});
