import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		configs: [],
		configsStoreId: {}
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/configs').then(({ data }) => {
				commit(types.STORE_CONFIGS, data);
				return data;
			})
		},
		fetchById({ commit }, { storeId }) {
			return axios.get('/configs/storeId', {
				params: {
					storeId
				}
			}).then(({ data }) => {
				commit(types.STORE_CONFIG_BYID, data);
				return data
			})
		},
		defaultAccount({ commit }, { type, accountId }) {
			return axios.put(`/configs/DefaultAccounts/${type}/${accountId}`).then((data) => data)
		},
		removeAccount({ commit }, { type }) {
			return axios.delete(`/configs/DefaultAccounts/${type}/remove`)
		},
		// main cashbox
		mainCashbox({ commit }, { cashboxId }) {
			return axios.put(`/configs/MainCashbox/${cashboxId}`)
		},
		vatPercent({ commit }, { value }) {
			return axios.put(`/configs/VatPercent/${value}`)
		},
		bankFeePercent({ commit }, { value }) {
			return axios.put(`configs/BankFeePercent/${value}`)
		},
		updateConfig({ commit }, { 
			salesAccountId, 
			purchasesAccountId, 
			salesRefundAccountId,
			purchasesRefundAccountId,
			grantedDiscountId,
			earnedDiscountId,
			vatAccountId,
			vatPercent,
			mainCashBoxId,
			defaultCashCustomerId,
			defaultImporterId,
			trn,
			bankAccountId,
			bankFeeAccountId,
			bankFeePercent,
			cashboxesReconciliationId
		}) {
			return axios.put('/Configs', {
				salesAccountId, 
				purchasesAccountId, 
				salesRefundAccountId,
				purchasesRefundAccountId,
				grantedDiscountId,
				earnedDiscountId,
				vatAccountId,
				vatPercent,
				mainCashBoxId,
				defaultCashCustomerId,
				defaultImporterId,
				trn,
				bankAccountId,
				bankFeeAccountId,
				bankFeePercent,
				cashboxesReconciliationId
			})
		},

		reset({ commit }) {
			return commit(types.RESET)
		}
	},

	mutations: {
		[types.STORE_CONFIGS](state, configs) {
			state.configs = configs;
		},
		[types.STORE_CONFIG_BYID](state, configsStoreId) {
			state.configsStoreId = configsStoreId;
		},
		[types.RESET](state) {
			state.configs = [];
			state.configsStoreId = {}
		}
	}
}