export default {
	home: 'Anasayfa',
	'items-tree': 'Öğe ağacı',
	'accounts-tree': 'Hesap ağacı',
	stores: 'Depo',
	'all-invoices': 'Tüm faturalar',
	'sale-invoice': 'Satış faturası',
	'sales-invoice-returned': 'Satış fatura iadesi',
	'pruchase-invoice': 'Satın alma faturası',
	'store-cashboxes': 'Nakitler',
	reports: 'Raporlar',
	statment: 'Açıklama',
	'item-inventory': 'Ürün envanteri',
	units: 'Birimler',
	user: 'Kullanıcı',
	'choose-store': 'Depo Seçin',
	'account-statement': 'Hesap özeti',
	'all-journals': 'Tüm nakit yevmiye ',
	'cashbox-journal': 'Nakit yevmiye',

	'journals-items': 'Yevmiye öğeler',
	'journal-item': 'Yevmiye öğe',
	'all-journals-items': 'Tüm yevmiye defterleri',
	'items-journal-table': 'Öğeler yevmiye tablosu',
	users: 'Kullanıcılar',
	// 'setting-store': 'Setting store',
	'store-info': 'Store info',
	'switch-store': 'Switch store',
	'store-settings': 'Depo ayarları',
	'are-you-sure-you-want-to-logout': 'Çıkış yapmak istediğinizden emin misiniz?',
	'daily-journal': 'Günlük yevmiye defteri',
	'funds-journal-table': 'Fonlar yevmiye tablosu'
}