<template>
    <div class="bgColor rounded-t-xl" style="height: 90vh">
        <v-row justify="end" class="px-3">
			<v-col cols="3">
				<bee-date-input
					v-model="date"
					:label="$t('forms.the-date-of-the-last-match')"
					input-format-order="DD-MM-YYYY"
					prepend-icon="mdi-calendar"
					dense
					hide-details
					disabled
				></bee-date-input>
			</v-col>
			<v-spacer />
            <!-- edit -->
            <v-col cols="auto" class="px-1">
                <v-btn
					color="edit-color white--text"
					:disabled="permissionsForUser.userType === userType.dataReader"
					@click="$route.query.preview == 1 ? $router.replace({
						query: {
							materialId: $route.query.material,
							withParents: $route.query.withParents,
							itemsWithBalancesOnly: $route.query.itemsWithBalancesOnly,
						}
					}) : match() "
				>
                    {{ $route.query.preview === 1 ? $t('edit') : $t('InventoryMatching.matching')}}
                </v-btn>
            </v-col>
            <!-- back -->
            <v-col cols="auto">
                <v-btn
					:color=" $route.query.preview ? 'blue-dark white--text' : 'alert-color white--text'"
					:to="{
						name: 'item-inventory',
						query: {
							materialId: $route.query.material,
							withParents: $route.query.withParents,
							itemsWithBalancesOnly: $route.query.itemsWithBalancesOnly,
						}
					}"
				>
                    {{ $route.query.preview ? $t('back') : $t('cancel')}}
                </v-btn>
            </v-col>
        </v-row>

		<!-- table -->
		<bee-handy-smart-table
			v-model="tableInputs"
			ref="table"
			:headers="headers"
			:items="materials"
			class="mt-2 px-3"
			:editable-rows="isEditItem"
			hide-default-footer
			zebra
			:loading="loading"
			dense
			@keypress.enter="editItem()"
			:row-highlight-expression="item => item.quantity.quantity !== item.actualQuantity"
			row-highlight-expression-class="edit-color lighten-5"
			pagination-on-scroll
		>
		</bee-handy-smart-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { type, itemCategoryType, contextItems, dateSortMode, userType } from '@/helpers/enums'
import rules from '@/helpers/validation rules'
import moment from 'moment'
export default {
	name: 'InventoryMatching',

	data() {
		return {
			loading: false,
			tableInputs: {},
			rowEditIndex: null,
			isEditItem: true,
			resetInputsFocus: false,
			rules,
			type,
			itemCategoryType,
			materials: [],
			actualQuantity: null,
			contextItems,
			dateSortMode,
			date: null,
			userType
		}
	},
	computed: {
		headers() {
			const headers = [
				{
					text: this.$t('headers.item-name'),
					name: 'itemId',
					value: 'itemId',
					setValueExpr: val => this.items.find(c => c.id === val && c.type === 2 && c.category !== 6)?.name ?? val,
					noInput: true,
				},
				{
					text: this.$t('headers.remaining-quantity'),
					name: 'quantity.quantity',
					value: 'quantity.quantity',
					width: 200
				},
				{
					text: this.$t('headers.the-actual-quantity'),
					name: 'actualQuantity',
					value: 'actualQuantity',
					noInput: false,
					disableInput: false,
					writable: !this.$route.query.preview,
					type: 'number'
				},
				{
					text: this.$t('headers.justification'),
					name: 'notes',
					value: 'notes',
					noInput: false,
					writable: !this.$route.query.preview
				}
			]
			return headers
		},
		...mapState({
			itemsInventory: state => state.statisticsReports.itemsInventory,
			itemsInventoryArray: state => state.statisticsReports.itemsInventory.inventoryRows.map(c => ({
				...c,
				actualQuantity: c.quantity.quantity,
				note: ''
			})),
			items: state => state.items.items,
			journals: state => state.journals.journals,
			permissionsForUser: state => state.auth.permissionsForUser
		}),

		// itemsInventory() {
		// 	return this.itemsInventory
		// },

		...mapGetters({
			getItemById: 'items/getItemById',
		}),
	},
	methods: {
		moment,
		fetchItemsInventories() {
			const itemFilter = this.items.filter(c => c.type === type.sub && c.category !== itemCategoryType.manufactured).map(c => c.id);
			return this.itemsInventory.inventoryRows.filter(c => itemFilter.includes(c.itemId));
		},

		getInventories() {
			return this.$store.dispatch('statisticsReports/fetchItemsInventory', { 
				materialId: this.$route.query.material,
				withParents: this.$route.query.withParents ? this.$route.query.withParents : false, 
				itemsWithBalancesOnly: this.$route.query.itemsWithBalancesOnly ? this.$route.query.itemsWithBalancesOnly : true
			}).then(() => {
				this.materials = this.fetchItemsInventories().map(c => ({
					...c,
					actualQuantity: c.quantity.quantity,
					notes: ''
				}));
			})
		},

		match() {
			console.log(this.materials)
			this.loading = true
			this.$store.dispatch('statisticsReports/fetchMatchingInventory', { 
				entries: this.materials.filter(c => c.actualQuantity !== c.quantity.quantity).map(c => ({ 
					itemId: c.itemId, 
					quantity: { quantity: c.actualQuantity, unitSizeId: c.quantity.unitSizeId },
					notes: c.notes 
				}))
			}).then(() => {
				this.$router.replace({
					name: '',
					query: {
						preview: 1,
						withParents: true,
						itemsWithBalancesOnly: true
					}
				})
				this.getInventories().finally(() => {
					this.loading = false
				})
			}).catch(() => {
				this.loading = false
			})
		}
	},

	created() {
		this.$store.dispatch('itemJournals/fetchAll', { context: contextItems.reconciliation, DateSortMode: dateSortMode.ascending }).then((data) => {
			this.date = this.moment(data[data.length - 1].date).format('YYYY-MM-DD')
		})
		if (this.itemsInventory.inventoryRows.length === 0) {
			this.loading = true
			this.getInventories().finally(() => {
				this.loading = false
			})
		} else {
			this.materials = this.fetchItemsInventories().map(c => ({
				...c,
				actualQuantity: c.quantity.quantity,
				notes: ''
			}));
		}
	},
	metaInfo() {
		return {
			title: this.$t('routes.inventory-matching'),
			titleTemplate: `%s | ${this.$t('routes.item-inventory')} | POS`
		}
	}
}
</script>
