export default {
	'tabs-title': {
		'detailed-sales-statement-for-an-item': 'Ürün satışları',
		'total-sales-to-a-customer': 'Bir müşteriye yapılan toplam satış',
		'total-sales': 'Toplam satış',
		'detailed-account-statement': 'Detaylı hesap özeti',
		'vat-statement': 'KDV beyanı'
	},
	item: 'Öğe',
	account: 'Hesap',
	'download-in-excel-format': 'Excel formunda indir'
}