export default {
	'add-journal-cashbox': 'Nakit yevmiye ekleme',
	'adding-journal-cashbox': 'Nakit yevmiye ekleme',
	'delete-journal': 'Günlüğü sil',
	restriction: 'Kısıtlama',
	'total-received': 'Toplam Alınan',
	'paid-total': 'Toplam Ödenen',
	'new-journal': 'Yeni yevmiye defteri',
	'the-difference': 'Fark',
	'the-journal-has-been-migrated-successfully': 'Yevmiye defteri başarıyla taşındı',
	'journal-saved-successfully': 'Yevmiye defteri başarıyla kaydedildi',
	journal: 'Yevmiye defteri',
	'alert-message-for-date-matching': 'Uyarı: yevmiye defteri tarihi bugünün tarihiyle uyuşmuyor'
}