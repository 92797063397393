<template>
    <div class="bgColor rounded-t-xl" style="height: 80vh">
		<v-form ref="form">
			<v-row align="center" class="mx-2 mt-0">
				<!--item id or name  -->
				<v-col cols="5" lg="3" md="4" sm="4" class="py-md-0">
					<v-autocomplete
						v-model="accountId"
						:items="accounts"
						item-text="name"
						item-value="id"
						:label="$t('account-id-or-name')"
						outlined
						dense
						hide-details="auto"
						:loading="loading"
						:disabled="loading"
						@keypress.enter="fetchAccountStatement()"
						:rules="rules.required"
						:dir="$i18n.locale === 'ar' ? 'rtl' : 'ltr'"
					/>
				</v-col>
				<!-- from date  -->
				<v-col cols="5" lg="2" md="3" sm="4" class="py-md-0">
					<bee-date-input
						v-model="fromDate"
						:label="$t('forms.from-date')"
						input-format-order="DD-MM-YYYY"
						prepend-icon="mdi-calendar"
						dense
						hide-details="auto"
						:loading="loading"
						:disabled="loading"
						@keypress.enter="accountId !== null ? fetchAccountStatement() : null"
					></bee-date-input>
				</v-col>
				<!-- toDate -->
				<v-col cols="5" lg="2" md="3" sm="3" class="py-md-0">
					<bee-date-input
						v-model="toDate"
						:label="$t('forms.to-date')"
						input-format-order="DD-MM-YYYY"
						prepend-icon="mdi-calendar"
						dense
						hide-details="auto"
						:loading="loading"
						:disabled="loading"
						@keypress.enter="accountId !== null ? fetchAccountStatement() : null"
					></bee-date-input>
				</v-col>

				<!-- search btn -->
				<v-col cols="auto" class="px-0 ">
					<v-btn 
						class="mx-2"
						fab
						x-small
						color="of-white"
						@click="fetchAccountStatement()"
						:loading="loading"
						:disabled="loading || accountId === null"
					>
						<v-icon color="blue-dark">mdi-magnify</v-icon>
					</v-btn>
				</v-col>

				<!-- close btn -->
				<v-col cols="auto" class="px-0 ">
					<v-btn 
						class="mx-2"
						fab
						x-small
						color="of-white"
						:disabled="loading || accountId === null"
						@click="reset()"
					>
						<v-icon color="alert-color">mdi-close</v-icon>
					</v-btn>
				</v-col>

				<v-spacer />

				<v-divider vertical class="my-3" /> 

				<v-col cols="auto">
					<v-btn
						:loading="downloadStatementLoading"
						:disabled="downloadStatementLoading || loading || !isSearch"
						@click="downloadStatement()"
						color="blue-dark white--text"
					>
						<v-icon class="me-1"> mdi-tray-arrow-down </v-icon>
						{{$t('download')}}
					</v-btn>
				</v-col>
			</v-row>
		</v-form>
		<!-- table -->
		<v-row>
			<v-col class="py-0">
				<bee-handy-table 
					:headers="headers"
					:items="!loading ? accountStatementRows : []"
					zebra
					fixed-header
					class="mx-3"
					dense
					pagination-on-scroll
					:height="$vuetify.breakpoint.height - 220"
				>
					<!-- balance -->
					<template v-slot:item.date="{ value }">
						<span> {{ value === null ? '-' : moment(value).format('YYYY-MM-DD')}} </span>
					</template>

					<!-- balance -->
					<template v-slot:item.balance="{ value }">
						<span dir="ltr"> {{ numberFormat(value) }} </span>
					</template>
				</bee-handy-table>
			</v-col>
		</v-row>
		<!-- footer -->
		<v-footer app inset color="white" class="py-4 elevation-0 ">
			<v-row>
				<v-col>
					{{ $t('AccountStatement.total-arrested') + '  '}}
					<!-- {{Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(, )}} -->
					{{numberFormat(accountStatement.summary.sumCredits.amount)}}
				</v-col>
				<v-col>
					{{ $t('AccountStatement.total-payments') }}
					{{ numberFormat(accountStatement.summary.sumDebits.amount) }}
				</v-col>

				<v-spacer />

				<v-col>
					{{ $t('AccountStatement.the-difference') + '  '}}
					<span 
						:class="{ 
							'alert-color--text': accountStatement.summary.sumDifference.moneyType === 1, 
							'success--text':  accountStatement.summary.sumDifference.moneyType === 0
						}"
					>
						{{ numberFormat(accountStatement.summary.sumDifference.amount) }}
					</span>
				</v-col>
			</v-row>
		</v-footer >
    </div>
</template>

<script>
import { mapState } from 'vuex'
// import { printHtml } from '@/helpers/functions'
import moment from 'moment'
import rules from '@/helpers/validation rules'
export default {
	name: 'accountStatement',

	data() {
		return {
			toDate: this.moment().format('YYYY-MM-DD'),
			fromDate: moment().startOf('year').format('YYYY-MM-DD'),
			// toDate: null,
			// fromDate: null,
			accountId: null,
			checkboxOne: false,
			loading: false,
			downloadStatementLoading: false,
			isSearch: false,
			rules
		}
	},
	computed: {
		...mapState({
			accounts: state => state.accounts.accounts,
			accountStatementRows: state => state.statisticsReports.accountStatement.accountStatementRows,
			accountStatement: state => state.statisticsReports.accountStatement,
		}),
		headers() {
			const header = [
				{
					text: this.$t('headers.date'),
					name: 'date',
					value: 'date',
					// setValueExpr: val => val === null ? '-' : this.moment(val).format('YYYY-MM-DD')
				},
				{
					text: this.$t('headers.revenue'),
					// revenue
					name: 'debit',
					value: 'debit',
					setValueExpr: val => val.amount
				},
				{
					text: this.$t('headers.expense'),
					name: 'credit',
					value: 'credit',
					setValueExpr: val => val.amount
				},
				{
					text: this.$t('headers.notes'),
					name: 'notes',
					value: 'note',
				},
				{
					text: this.$t('headers.balance'),
					name: 'balance',
					value: 'balance'
				},
			]
			return header
		}
	},

	methods: {
		moment,
		numberFormat(value) {
			return this.$options.filters.numberFormat(value);
		},
		fetchAccountStatement() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				this.$store.dispatch('statisticsReports/getAccountStatement', { 
					accountId: this.accountId, 
					fromDate: this.fromDate, 
					toDate: this.toDate 
				}).then(() => {
					this.isSearch = true
				}).finally(() => {
					this.loading = false
				})
			}
		},
		reset() {
			this.fromDate = this.moment().startOf('year').format('YYYY-MM-DD') 
			this.toDate = this.moment().format('YYYY-MM-DD')
			this.accountId = null
			this.$store.dispatch('statisticsReports/reset')
			this.isSearch = false
			this.$refs.form.reset()
		},
		downloadStatement() {
			this.downloadStatementLoading = true
			this.$store.dispatch('reports/accountStatement', {
				storeId: this.$localStorage.storeId,
				accountId: this.accountId,
				fromDate: this.fromDate,
				toDate: this.toDate
			}).finally(() => {
				this.downloadStatementLoading = false
			})
		}
	},

	metaInfo() {
		return {
			title: this.$t('routes.account-statement'),
			titleTemplate: '%s | POS'
		}
	}
}
</script>
<style scoped>
 .m-minus {
	margin-top: -0.5rem;
 }
</style>