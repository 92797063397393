<template>
	<div class="bgColor rounded-t-xl" style="height: 92vh">
		<v-row align="center" class="px-3">
            <v-col sm="auto" md="3" cols="4" align-self="center" class="d-flex align-center pa-1 mx-2">
				<span>
					{{ moment(date).locale($i18n.locale).lang($i18n.locale).format('dddd') }}
				</span> 
				<template>
					<tooltip right :text="$t('FundsJournalTable.previous')">
						<v-btn @click="downDay(); search()" icon>
							<v-icon>  {{ $i18n.locale === 'ar' ? 'mdi-menu-right' : 'mdi-menu-left' }} </v-icon>
						</v-btn>
					</tooltip>
					<bee-date-input
						v-model="date"
						input-format-order="DD-MM-YYYY"
						dense
						hide-details
					>
					</bee-date-input>
					<tooltip left :text="$t('FundsJournalTable.next')">
						<v-btn @click="upDay(); search()" icon>
							<v-icon>{{ $i18n.locale === 'ar' ? 'mdi-menu-left' : 'mdi-menu-right' }}</v-icon>
						</v-btn>
					</tooltip>
				</template>
			</v-col>

			<v-col cols="2">
				<v-autocomplete
					v-model="cashbox"
					:items="cashboxes"
					item-text="name"
					item-value="id"
					:label="$t('cashbox')"
					dense
					hide-details="auto"
					outlined
					:disabled="loading"
					@change="search()"
				/>
			</v-col>

			<!-- speacer -->
			<v-spacer />

			<!-- save btn -->
			<!-- <v-col cols="auto" class="d-flex justify-end">
				<v-btn
					color="blue-dark white--text"
					@click="search()"
					:loading="loading"
					:disabled="loading"
				>
					{{$t('search')}}
				</v-btn>
			</v-col> -->

			<!-- delete btn -->
			<v-col v-if="0" cols="auto" class="d-flex justify-end">
				<v-btn
					color="alert-color white--text"
					:disabled="loading"
					@click="reset()"
				>
					{{$t('cancel')}}
				</v-btn>
			</v-col>
		</v-row>
		<!-- table -->
        <bee-handy-table
			v-model="tableInputs"
			ref="table"
			:headers="headers"
			:items="!loading ? dayStatementReportRows : []"
			show-add-row
			hide-default-footer
			fixed-header
			:height="$vuetify.breakpoint.height - 220"
			dense
			class="my-3 mx-3"
			:loading="loading"
			pagination-on-scroll
		>
			<!-- itemId -->
			<template v-slot:input.itemId="{ on, attr }">
				<v-autocomplete
					:items="items.filter(c => c.type === type.sub)"
					item-value="id"
					:item-text="(item) => `${item.no} - ${item.name}`"
					auto-select-first
					v-on="on"
					v-bind="attr"
				></v-autocomplete>
			</template>

			<!-- actions -->
			<template v-slot:item.action="{ item }">
				<v-btn
					small
					icon
					:disabled="loading"
					color="edit-color"
					@click="
					item.accountingJournalId !== null && item.invoiceId === null ? 
						$router.push({
							name: 'cashbox-journal',
							params: {
								id: item.accountingJournalId
							}
						}) : 
						item.invoiceType === 1 ? 
							$router.push({
								name: 'sale-invoice',
								params: {
									id: item.invoiceId
								}
							}) :
							$router.push({
								name: 'pruchase-invoice',
								params: {
									id: item.invoiceId
								}
							})
					"
				>
					<v-icon>mdi-eye</v-icon>
				</v-btn>
			</template>
		</bee-handy-table>

		<v-footer absolute height="50px" color="white" class="elevation-0">
			<v-row>
				<v-col cols="auto">
					{{
						$t('FundsJournalTable.footer.total-received') + ' :  ' 	+ '  '		
					}}
					{{
						daySummary.summary.sumCredits.amount
					}}
				</v-col>
				<v-col cols="auto">
					{{$t('FundsJournalTable.footer.paid-total') + ' :  ' + '  '}}
					{{
						daySummary.summary.sumCredits.amount
					}}
				</v-col>

				<v-spacer />

				<v-col cols="auto">
					{{ $t('FundsJournalTable.footer.the-difference') }}
					{{
						daySummary.summary.sumDifference.amount
					}}
				</v-col>
			</v-row>
		</v-footer >
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { type } from '@/helpers/enums'
import Tooltip from '@/components/Tooltip.vue';
// import ItemsNavigator from '@/components/ItemsNavigator.vue';
import moment from 'moment'

export default {
	name: 'JournalItem',

	components: {
		Tooltip
	},

	data() {
		return {
			tableInputs: {},
			entries: [],
			type,
			// date: this.moment().format('YYYY-MM-DD')
			date: this.moment().format('YYYY-MM-DD'),
			loading: false,
			cashbox: null
			
		}
	},

	computed: {
		...mapState({
			items: state => state.items.items,
			cashboxes: state => state.cashboxes.cashboxes,
			daySummary: state => state.statisticsReports.daySummary,
			dayStatementReportRows: state => state.statisticsReports.daySummary.dayStatementReportRows,
			journals: state => state.cashJournals.journals,
			accounts: state => state.accounts.accounts
		}),
		...mapGetters({
			getCashJournalById: 'cashJournals/getCashJournalById',
			getAccountById: 'accounts/getAccountById',
		}),
		headers() {
			const headers = [
				{
					text: this.$t('headers.serial'),
					name: 'no',
					value: 'no',
					setValueExpr: (val, index) => index + 1,
					noInput: true,
				},
				{
					text: this.$t('headers.journal-on-bound'),
					name: 'accountingJournalId',
					setValueExpr: val => val !== null ? this.getCashJournalById(val)?.cashJournalNo : '-',
					value: 'journal-no',
					noInput: false,
					noNotAvailableText: true
				},
				{
					text: this.$t('headers.account'),
					name: 'account',
					value: 'accountId',
					setValueExpr: val => this.getAccountById(val)?.name,
					noInput: false,
				},
				{
					text: this.$t('headers.receipt'),
					name: 'receipt',
					value: 'credit',
					setValueExpr: val => val.amount,
					width: 140,
					disableInput: this.isEditItem,					
					noInput: false,
				},
				{
					text: this.$t('headers.payment'),
					name: 'debit',
					value: 'debit',
					setValueExpr: val => val.amount,
					noInput: false,
				},
				{
					text: this.$t('headers.explanation'),
					name: 'note',
					value: 'note',
					noInput: false,
				},
				{
					text: this.$t('headers.date'),
					name: 'date',
					value: 'date',
					// setValueExpr: val => this.moment(val),	
					// moment.lang("de").format('LLL')
					// setValueExpr: val => this.moment(val).moment.format('YYYY-MM-DD, h:mm a'),
					setValueExpr: val => this.moment(val).format('MMMM Do YYYY, h:mm:ss a'),
					noInput: false,
				}, 
				{
					text: '',
					name: 'action',
					value: 'action'
				}
			]
			return headers
		}
	},
	methods: {
		moment,
		// upDay() {
		// 	this.date = this.moment(this.fromDate).add(1, 'day').format('YYYY-MM-DD')
		// 	return this.date
		// },

		// downDay () {
		// 	this.fromDate = this.moment(this.fromDate).subtract(1, 'day').format('YYYY-MM-DD')
		// 	return this.fromDate
		// }

		upDay () {
			this.date = this.moment(this.date).add(1, 'day').format('YYYY-MM-DD')
			return this.date
		},
		downDay () {
			this.date = this.moment(this.date).subtract(1, 'day').format('YYYY-MM-DD')
			return this.date
		},
		search() {
			this.loading = true
			this.$store.dispatch('statisticsReports/getDaySummary', {
				forDate: this.date,
				cashboxId: this.cashbox,
				compoundType: 2
			}).finally(() => {
				this.loading = false
			})
		},
		reset() {
			this.cashbox = null;
			this.date = this.moment().format('YYYY-MM-DD')
			this.search()
		}
	},

	created() {
		this.loading = true; 
		Promise.all([
			this.$store.dispatch('cashJournals/fetchAll', {}),
			this.search()
		]).finally(() => {
			this.loading = false
		})
	},
	metaInfo() {
		return {
			title: this.$t('routes.funds-journal-table'),
			titleTemplate: '%s | POS'	
		}
	}
}
</script>
