export default {
	all: 'Tümü',
	favorite: 'Favori ',

	'edit-fav-list': 'Favori listeyi düzenle',

	'total-without-vat': 'KDV olmadan toplam',
	'total-net': 'Net toplam',
	paid: 'Ödenen',
	unpaid: 'geriye kalan',
	alert: 'Uyarı',
	'alert-message': 'Fiyatlar, varsa bu hesap için tahsis edilen fiyatlarla değiştirilecektir'
}