export default {
	'select-item': 'Lütfen öğeyi seçin',

	'the-item': 'öğe?',

	'main-info': 'Ana Bilgi',
	'item-name': 'Öğe adı',
	'main-item': 'Ana Öğe',
	'item-no-automatic-or-manual': 'Öğe numarası (otomatik ya da manuel)',

	'item-type': 'Öğe türü',

	'item-classification': 'Öğe sınıflandırması',
	unit: 'Birim',

	'trading-info': 'Ticaret bilgileri',
	'selling-price': 'Satış fiyatı',
	min: 'Minumum',
	max: 'Maksimum',
	manufacturer: 'Üretici',
	notes: 'Notlar',
	'favorite-item': 'Favori öğe',
	unsaleable: 'Satılamaz',
	'the-cost-of-the-input-items': 'Ürünlerin maliyeti girildi',
	// 'ingredients': 'ingredients'
	ingredients: 'İçerikler',
	'the-difference': 'Fark',
	'for-one-of-$-from-$': '{birim} için {ürün} lerden birini seçin',
	'the-component-has-been-modified-successfully': 'Bileşen başarılı bir şekilde değiştirildi',
	'mixture-ingredient': 'Karışım içeriği',
	'purchasing-price': 'Satın alma fiyatı',
	'add-ingredient-after-save-item': 'Lütfen malzemeyi kaydettikten sonra bileşenleri ekleyin',
	'this-item-has-been-added-before-you-can-edit-the-item-instead': 'Bu makale daha önce eklenmişti. Bunun yerine makaleyi düzenleyebilirsiniz.'
}