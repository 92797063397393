export default {
	casher: 'Kasiyer',
	manager: 'Müdür',
	name: 'Adı',
	balance: 'Bakiye',
	specify: 'Belirtiniz',
	notDefined: 'Belirtilmemiş',
	operation: 'Çalışma',
	trade: 'Ticaret',
	gainsAndLosses: 'Kazançlar ve kayıplar',
	budget: 'Bütçe',
	'types-of-final-accounts': {
		notDefined: 'Belirtilmemiş',
		operation: 'Çalışma',
		trade: 'Ticaret bilgileri',
		'gains-and-losses': 'Kazançlar ve kayıplar',
		budget: 'Bütçe',
		account: 'Hesap'
	},
	'default-account-names': {
		'sales-account': 'Satış hesabı',
		'purchases-account': 'Satın alma hesabı',
		'sales-return-account': 'Satış iade hesabı  ',
		'purchases-return-account': 'Satın alımlar iade hesabı',
		'the-discount-account-granted': 'Satın alımlar iade hesabı  ',
		'earned-discount-account': 'Kazanılan-indirim-hesabı',
		'tax-account': 'Vergi hesabı',
		'cash-customer': 'Nakit müşteri ',
		'default-resource': 'Varsayılan kaynak ',
		'bank-account': 'Banka hesabı',
		'bank-fee-account': 'Banka ücret yüzdesi',
		'vat-number': 'KDV numarası',
		'main-cashBoxId': 'Ana fon',
		'vat-percent': 'Vergi oranı',
		'bank-fee-percent': 'Banka komisyon oranı',
		'account-cashboxes-reconciliation': 'Nakit fon mutabakat hesabı'
	},
	'store-archive': 'Depo arşivi',
	'store-default-settings': 'Varsayılan ayarları kaydet',
	reappointment: 'Yeniden Randevu',
	reset: 'Sıfırlama',
	titles: {
		cashboxes: 'Kasa ',
		tax: 'Vergi ',
		'intermediary-accountsId': 'Aracı hesap kimliği ',
		sales: 'Satış',
		purchase: 'Satın alma',
		bank: 'Banka'
	},
	currency: 'Para birimi',
	'attach-logo': 'Logo ekleyin',
	'import-store-materials': 'Depo malzemelerinin ithali ',
	address: 'Adres',
	phone: 'Telefon ',
	// 'please-log-in-to-the-store-context-to-add-settings': '(Please log in to the store context to add settings)',
	'import-item-tree': 'Öğe ağacını içe aktar',
	'export-item-tree': 'Öğe ağacını dışa aktar  ',
	'confirm-import-of-material-tree': 'Malzeme ağacının içe aktarımını onaylayın',
	'are-you-sure-you-want-to-import-the-material-tree-for-the-current-store?': 'Mevcut mağaza için malzeme ağacını içe aktarmak istediğinizden emin misiniz?',
	'the-material-tree-import-process-is-irreversible-and-may-take-some-time': 'Malzeme ağacı ithalat süreci geri döndürülemez ve biraz zaman alabilir',
	tracking: 'Takip',
	'default-language': 'Varsayılan dil',
	importing: 'İthalat',
	exporting: 'İhracat',
	'export-import-tree-item': 'Ağaç öğesini dışa/içe aktarın:',
	language: {
		arabic: 'Arapça',
		english: 'İngilizce',
		spanish: 'İspanyolca'
	},
	'the-import-process-was-successful': 'İçe aktarma işlemi başarılı oldu',
	'the-store-is-materials-file-has-been-successfully-exported-to-the-Downloads-folder': 'Depo malzemeleri dosyası İndirilenler klasörüne başarıyla aktarıldı',
	'initial-number-of-sales-invoices': 'Satış faturalarının ilk sayısı',
	'your-tax-report-results-will-be-affected': 'Vergi raporu sonuçlarınız etkilenecektir',
	alert: 'Uyarı',
	'store-name-in-reports-(English)': 'Raporlarda depo adı (İngilizce)',
	'store-name-in-reports-(Arabic)': 'Raporlarda depo adı (Arapça)'
}