export default {
	tabs: {
		all: 'Tümü',
		sales: 'Satışlar',
		purchases: 'Satın Almalar',
		'sales-refund': 'Satış Reddi'
	},
	sale: 'Satış',
	purchases: 'Satın Alma',
	'sales-returns': 'Satış İadeleri',
	ascending: 'En eskiden',
	descending: 'En yeniye',
	'view-the-associated-invoice': 'İlgili faturayı görüntüle',
	'view-associated-journal': 'İlgili günlüğü görüntüle',
	print: 'Yazdır',
	'sort-by-status': 'Duruma göre sırala',
	'posted-first': 'Önce gönderildi',
	'not-posted-first': 'Önce gönderilemedi',
	// no: 'no'
}