<template>
	<div id="invoice">
		<!-- confirm discard dialog -->
		<v-dialog v-model="confirmDiscardDialog" width="400" persistent>
			<v-card>
				<v-card-title class="alert-color--text py-0">
					<v-spacer/>
					<span class="ms-8 text-uppercase">
						{{$t('delete')}}
					</span>
					<v-spacer/>

					<v-btn @click="confirmDiscardDialog = false" icon dark>
						<v-icon color="alert-color">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text class="pt-4 pb-2">
					{{ $t('messages.are-you-sure-to-delete-invoice-draft') }}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>

					<v-btn
						color="alert-color white--text"
						large
						height="34"
						@click="deleteInvoice()"
						:loading="deleteLoading"
					>
						{{$t('confirm')}}
					</v-btn>
					<v-btn
						large
						height="34"
						@click="confirmDiscardDialog = false"
						:disabled="deleteLoading"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!--dialog payment method  -->
		<v-dialog v-model="dialogPaymentMethod" width="400">
			<v-card>
				<v-card-title class="primary--text text-center py-1">
					<v-spacer/>
					<span class="ms-8 text-uppercase">
						{{$t('payment-method.label')}}
					</span>
					<v-spacer />
					<v-btn @click="dialogPaymentMethod = false" icon dark>
						<v-icon color="primary">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text class="pb-0 pt-1">
					{{ $t('PurchaseInvoice.the-net-invoice-must-be-zero-and-this-requires-zeroing-all-financial-fields') }}
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn @click="resetPrice()" text color="success">
						{{$t('ok')}}
					</v-btn>

					<v-btn @click="dialogPaymentMethod = false" text color="alert-color">
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
		<!-- Dialog alert -->
		<v-dialog v-model="alertDialog" width="350px">
			<v-card>
				<v-card-title class="orange--text py-0">
					<v-spacer/>
					<span class="ms-8 text-uppercase">
						{{$t('SaleInvoice.alert')}}
					</span>
					<v-spacer/>

					<v-btn @click="alertDialog = false" icon dark>
						<v-icon color="orange">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text class="pb-0 pt-1">
					{{ $t('SaleInvoice.alert-message') }}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="orange white--text"
						large
						height="34"
						@click="fetchSuggestedPurchasePriceForAll()"
					>
						{{$t('ok')}}
					</v-btn>
					<v-btn
						large
						height="34"
						@click="alertDialog = false"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- date alert dialog -->
		<v-dialog v-model="dateAlert.dialog" max-width="350">
			<v-card>
				<v-card-title class="orange--text py-0">
					<v-spacer/>
					<span class="ms-8 text-uppercase">
						{{$t('alerts.alert')}}
					</span>
					<v-spacer/>

					<v-btn
						icon
						dark
						@click="dateAlert.isDateChecked = false; dateAlert.dialog = false;"
					>
						<v-icon color="orange">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text class="pb-0 pt-1">
					{{ $t('alerts.check-date.message') }}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="orange white--text"
						large
						height="34"
						@click="
							submitData.date = moment();
							checkout(dateAlert.needPrint, dateAlert.printType);
						"
					>
						{{$t('alerts.check-date.today-date')}}
					</v-btn>
					<v-btn
						large
						height="34"
						@click="checkout(dateAlert.needPrint, dateAlert.printType)"
					>
						{{$t('alerts.check-date.invoice-date')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
		<v-row>
			<v-col lg="2" md="3" cols="12" >
				<!-- fav items -->
				<div style="height: 30vh; scroll-behavior: auto">
					<!-- tabs -->
					<v-btn-toggle
						v-model="activeItems"
						mandatory
						borderless
						active-class="primary white--text"
						class="d-flex elevation-2 mb-2"
					>
						<v-btn
							height="40"
							class="flex-grow-1"
							:class="$i18n.locale === 'ar' ? 'rounded-l-0' : 'rounded-r-0'"
						>
							{{$t('SaleInvoice.all')}}
						</v-btn>
						<v-btn
							width="40%"
							height="40"
							class="rounded-0"
						>
							{{$t('SaleInvoice.favorite')}}
						</v-btn>
						<v-btn
							height="40"
							class="flex-grow-1"
							:class="$i18n.locale === 'ar' ? 'rounded-r-0' : 'rounded-l-0'"
						>
							{{$t('search')}}
						</v-btn>
					</v-btn-toggle>
					
					<!-- items -->
					<v-slide-y-transition mode="out-in">
						<!-- favorite items & search -->
						<v-layout v-if="[1, 2].includes(activeItems)" wrap justify-space-between>
							<!-- barcode -->
							<v-expand-transition>
								<v-flex v-if="activeItems === 2" xs12>
									<v-text-field
										v-model="searchItem"
										:label="isSearchByBarcode ? $t('forms.barcode') : $t('search')"
										dense
										hide-details
										outlined
										:append-icon="isSearchByBarcode ? 'mdi-barcode' : 'mdi-barcode-off'"
										@click:append="isSearchByBarcode = !isSearchByBarcode"
										@keypress.enter="isSearchByBarcode ? addByBarcode() : null"
									></v-text-field>
								</v-flex>
							</v-expand-transition>

							<!-- items -->
							<v-flex
								v-for="item in filteredItems"
								:key="item.id"
								md6 xs3
								class="pa-1"
							>
								<button
									v-ripple
									class="d-flex justify-center align-center text-center text-capitalize grey lighten-3 rounded-medium px-2 elevation-2 font-weight-bold"
									:class="[{ 'elevation-0 grey--text': submitLoading || loading || isCurrentInvoicePosted }, item.name.length > 16 ? 'text-small' : 'text-body-2']"
									:disabled="submitLoading || loading || isCurrentInvoicePosted ||  permissionsForUser.userType === userType.dataReader"
									:style="{
										width: '100%',
										height: $vuetify.breakpoint.lgAndUp ? '55px' : '40px'
									}"
									@click="addFavItem(item)"
								>
									{{item.name}}
								</button>
							</v-flex>
						</v-layout>

						<!-- all items -->
						<v-expansion-panels
							v-else
							accordion
							id="all-items-panels"
							active-class="py-0"
						>
							<v-expansion-panel
								v-for="(rootItem,i) in saleableItemsTree"
								:key="i"
							>
								<v-expansion-panel-header>
									{{rootItem.name}}
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-layout wrap justify-space-between class="pb-2">
										<v-flex
											v-for="item in rootItem.children"
											:key="item.id"
											md6 xs3
											class="pa-1"
										>
											<button
												v-ripple
												class="d-flex justify-center align-center text-center text-capitalize grey lighten-3 rounded-medium px-2 elevation-1 font-weight-bold"
												:class="[{ 'elevation-0 grey--text': submitLoading || loading || isCurrentInvoicePosted }, item.name.length > 16 ? 'text-small' : 'text-body-2']"
												:disabled="submitLoading || loading || isCurrentInvoicePosted || permissionsForUser.userType === userType.dataReader"
												:style="{
													width: '100%',
													height: $vuetify.breakpoint.lgAndUp ? '55px' : '40px'
												}"
												@click="addFavItem(getItemById(item.id))"
											>
												{{item.name}}
											</button>
										</v-flex>
									</v-layout>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-slide-y-transition>

					<template v-if="0">
						<v-divider class="my-3"/>
						
						<!-- Edit fav list -->
						<v-btn
							:width="$vuetify.breakpoint.mdAndUp ? '100%': '50%'"
							height="35"
							color="edit-color white--text mb-md-2"
							class="text-capitalize"
						>
							{{$t('SaleInvoice.edit-fav-list')}}
						</v-btn>
					</template>
				</div>
			</v-col>

			<!-- table -->
			<v-col lg="8" md="9" cols="12" class="rounded-t-xl bgColor pt-lg-8 pt-6 px-lg-5 px-3 pb-0">
				<!-- top row -->
				<v-row class="mb-2">
					<v-col md="2" sm="4" cols="6" class="pa-1">
						<items-navigator
							v-model="invoiceNo"
							:label="$t('invoice-no')"
							:disabled="loading"
							:items="
								invoices.filter(
									c => permissionsForUser.userType === userType.casher
									? c.cashboxId === permissionsForUser.cashboxId
									: true
								)
							"
						></items-navigator>
					</v-col>

					<v-col v-if="$vuetify.breakpoint.mdAndDown" class="pa-1">
						<!-- date in md and down size -->
						<bee-date-input
							v-if="$vuetify.breakpoint.mdAndDown"
							v-model="submitData.date"
							:label="$t('forms.date')"
							input-format-order="DD-MM-YYYY"
							prepend-icon="mdi-calendar"
							dense
							hide-details
							class=""
							:disabled="submitLoading || loading"
							:readonly="isCurrentInvoicePosted"
							:loading="loading"
							fit-inputs
							iso-date
						></bee-date-input>
					</v-col>

					<v-spacer v-if="!$vuetify.breakpoint.mdAndDown" />

					<v-col cols="auto" class="pa-1 pe-md-4 py-1">
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									dark
									v-bind="attrs"
									v-on="on"
									elevation="0" 
									color="blue-dark white--text" 
									width="100%" 
									height="35" 
									:disabled="submitLoading || loading "
								>
									{{$t('show')}}
									<v-icon size="20" class="ms-1">mdi-chevron-down</v-icon>
								</v-btn>
							</template>
							<v-list dense>
								<v-list-item 
									:to="{
										name: 'all-invoices',
										query: { type: invoiceTypes.sales }
									}"
								>
									<v-list-item-title> 
										{{$t('all-invoice')}}
									</v-list-item-title>
								</v-list-item>

								<v-list-item 
									:to="{
										name: 'journal-item',
										params: {
											id: getInvoiceById($route.params.id)?.itemsJournalId
										}
									}"
									:disabled="!getInvoiceById($route.params.id)?.isPosted"
								>
									<v-list-item-title> 
										{{$t('view-invoice-details.related-items-journal')}}
									</v-list-item-title>
								</v-list-item>

								<v-list-item 
									:to="{
										name: 'cashbox-journal',
										params: {
											id: getInvoiceById($route.params.id)?.cashJournalId
										}
									}"
									:disabled="!getInvoiceById($route.params.id)?.isPosted"
								>
									<v-list-item-title> 
										{{$t('view-invoice-details.related-cashbox-journal')}}
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
				</v-row>

				<!-- table -->
				<bee-handy-smart-table
					v-model="tableInputs"
					ref="table"
					:headers="headers"
					:items="entries"
					:items-per-page="-1"
					:loading="loading"
					:data-table-props="{
						disableSort: true,
						noDataText: $t('entery-value-and-prass-enter')
					}"
					fixed-header
					hide-default-footer
					dense
					:show-add-row="permissionsForUser.userType !== userType.dataReader"
					:editable-rows="!submitLoading && !loading && !isCurrentInvoicePosted"
					:height="$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.height - 256 : $vuetify.breakpoint.height - 145"
					:context-menu-items="contextMenuItems"
					stay-in-input-fields
					:validation-messages.sync="validationMessages"
					disable-mobile-context-menu
					hide-inputs-details
					@edit-cancelled="isEditItem = false"
					@context-menu-edit="setRowIndex"
					@context-menu-delete="deleteItem"
					@keypress.enter="!isEditItem ? addItem() : editItem()"
					@reached-last-input="!isEditItem ? addItem() : editItem()"
					@change-itemId="fetchSuggestedPurchasePrice()"
					zebra
					hot-inputs
					table-class="py-2 px-3"
					:disable-context-menu="submitLoading || loading || isCurrentInvoicePosted"
					:disable-input-fields="submitLoading || loading || isCurrentInvoicePosted"
					class="table"
				>

					<!-- price -->
					<template v-slot:input.price="{ on, attr }">
						<v-text-field
							:loading="loadingRequestPrice"
							v-on="on"
							v-bind="attr"
							hide-spin-buttons
						></v-text-field>
					</template>

					<!-- actions -->
					<template v-slot:item.actions="{ index }">
						<div class="d-flex">
							<!-- edit -->
							<v-btn
								outlined
								width="30"
								min-width="30"
								height="25"
								color="edit-color"
								class="me-2 pa-0"
								:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
								@click="$refs.table.activeEditForRow(index); setRowIndex({ index })"
							>
								<v-icon size="18">mdi-pencil</v-icon>
							</v-btn>

							<!-- delete -->
							<v-btn
								width="30"
								outlined
								min-width="30"
								height="25"
								color="alert-color"
								class="pa-0"
								:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
								@click="deleteItem({ index })"
							>
								<v-icon size="18">mdi-delete</v-icon>
							</v-btn>
						</div>
					</template>

					<!-- itemId -->
					<template v-slot:input.itemId="{ on, attr }">
						<v-autocomplete
							:items="items.filter(c => c.type === type.sub && !c.isUnsellable)"
							item-value="id"
							:item-text="(item) => `${item.no} - ${item.name}`"
							auto-select-first
							v-on="on"
							v-bind="attr"
							:readonly="loadingRequestPrice"
						></v-autocomplete>
					</template>

					<!-- quantity -->
					<template v-slot:input.quantity="{ on, attr }">
						<quantity-input
							v-on="on"
							v-bind="attr"
						></quantity-input>
					</template>

					<!-- percentDiscount -->
					<template v-slot:input.percentDiscount="{ on, attr}">
						<v-text-field
							prefix="%"
							v-on="on"
							v-bind="attr"
							hide-spin-buttons
						></v-text-field>
					</template>
				</bee-handy-smart-table>

				<!-- info in smAndDown size -->
				<v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12">
					<v-row>
						<v-col cols="4" class="pb-0">
							<!-- account -->
							<v-autocomplete
								v-model="submitData.account"
								:label="$t('forms.account')"
								:items="accounts.filter(c => c.type === type.sub && !c.isHidden)"
								item-value="id"
								:item-text="account => `${account.accountingNo} - ${account.name}`"
								outlined
								dense
								hide-details
								:rules="rules.required"
								:disabled="permissionsForUser.userType === userType.dataReader"
								@change="submitData.paymentMethodId !==  paymentMethod.internal && entries.length !== 0 ? alertDialog = true : null"
							></v-autocomplete>
							
							<!-- payment type -->
							<div class="d-flex justify-space-between align-center flex-wrap mb-1">						
								<v-autocomplete
									v-model="submitData.paymentMethodId"
									:items="paymentMethodArray"
									item-text="name"
									item-value="id"
									:label="$t('payment-method.label')"
									outlined
									dense
									hide-details="auto"
									:disabled="permissionsForUser.userType === userType.dataReader"
									@change="submitData.paymentMethodId === paymentMethod.internal ? dialogPaymentMethod = true : null"
								/>

								<template v-if="submitData.paymentMethodId === paymentMethod.debt">
									<!-- paid -->
										<!-- :disabled="submitLoading || loading || submitData.paymentMethodId === paymentMethod.cash || permissionsForUser.userType === userType.dataReader" -->
									<v-text-field
										v-model="paidFormatted"
										v-number-format.number="[paidFormatted, submitData.paid]"
										:label="$t('forms.paid')"
										dense
										hide-details
										class="mb-2"
										outlined
										:readonly="isCurrentInvoicePosted"
										:loading="loading"
										@change="!$event ? submitData.paid = null : null"
										:disabled="submitLoading || loading || [paymentMethod.cash, paymentMethod.creditCard, paymentMethod.internal].includes(submitData.paymentMethodId) || permissionsForUser.userType === userType.dataReader"
									></v-text-field>
									
									<!-- unpaid -->
									<div class="flex-grow-1 pa-0">
										<div class="d-flex justify-space-between align-center edit-color--text">
											<span class="text-subtitle-2 font-weight-medium">
												{{$t('forms.unpaid')}}
											</span>
											<span class="text-subtitle-1 font-weight-bold">
												{{unpaidAmount}}
											</span>
										</div>
									</div>
								</template>
							</div>
						</v-col>

						<v-divider class="my-2" vertical />
						<v-col cols="4" class="pb-0">
							<!-- totalNet -->
							<div class="d-flex justify-space-between">
								<span class="text-subtitle-2 font-weight-medium">{{$t('SaleInvoice.total-without-vat')}}</span>
								<span class="text-subtitle-1 font-weight-bold">
									{{numberFormat(entriesTotal)}}
								</span>
							</div>

							<!-- discount -->
							<v-text-field
								v-model="discountFormatted"
								v-number-format.number="[discountFormatted, submitData.discount]"
								:label="$t('forms.discount')"
								dense
								hide-details
								outlined
								class=""
								:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
								:readonly="isCurrentInvoicePosted"
								:loading="loading"
							></v-text-field>

							<!-- tax -->
							<div class="pa-0">
								<div class="d-flex justify-space-between align-center ">
									<span class="text-subtitle-2 font-weight-medium">
										{{$t('forms.tax')}}
									</span>
									<span class="text-subtitle-1 font-weight-bold">
										{{numberFormat(vatAmount)}}
									</span>
								</div>
							</div>

							<div class="pa-0">
								<div class="d-flex justify-space-between align-center">
									<span class="text-subtitle-2 font-weight-medium">
										{{$t('SaleInvoice.total-net')}}
									</span>
									<span class="text-subtitle-1 font-weight-bold">
										{{numberFormat(totalNet)}}
									</span>
								</div>
							</div>
						</v-col>
					
						<v-divider class="my-2" vertical />

						<v-col class="pb-0">
							<div class="d-flex align-center mb-1">
								<div class="flex-grow-1">
									<v-btn
										depressed
										height="40"
										width="70%"
										color="blue-dark white--text"
										class="text-capitalize pa-0"
										:class="$vuetify.rtl ? 'rounded-l-0' : 'rounded-r-0'"
										:disabled="submitLoading || loading || isCurrentInvoicePosted || totalNet < 0 || !submitData.account || !entries.length || permissionsForUser.userType === userType.dataReader"
										:loading="submitLoading || loading"
										@click="checkout(true, print.receipt)"
									>
										{{ !isCurrentInvoicePosted ? $t('check-out') : $t('checked-out')}}
									</v-btn>
									<v-menu offset-y>
										<template v-slot:activator="{ attr, on }">
											<v-btn
												small
												height="40"
												width="30%"
												min-width="30%"
												color="blue-dark white--text"
												class="print-menu-btn text-capitalize pa-0"
												:class="$vuetify.rtl ? 'rounded-r-0' : 'rounded-l-0'"
												:disabled="
													submitLoading || loading || totalNet < 0 ||
													!submitData.account || permissionsForUser.userType === userType.dataReader
												"
												depressed
												v-on="on"
												v-bind="attr"
											>
												<v-icon size="20">mdi-chevron-down</v-icon>
											</v-btn>
										</template>
									
										<v-list dense>
											<v-list-item
												:disabled="isCurrentInvoicePosted || !entries.length"
												@click="checkout(false)"
											>
												<v-list-item-title>
													{{$t('print.pay-without-print')}}
												</v-list-item-title>
											</v-list-item>
											<v-list-item
												:disabled="!isCurrentInvoicePosted"
												@click="checkout(true, print.saleInvoiceReport)"
											>
												<v-list-item-title>
													{{$t('print.print-invoice')}}
												</v-list-item-title>
											</v-list-item>
											<v-list-item
												:disabled="!isCurrentInvoicePosted"
												@click="checkout(true, print.deliveryReport)"
											>
												<v-list-item-title>
													{{$t('print.print-delivery')}}
												</v-list-item-title>
											</v-list-item>
											<v-list-item
												:disabled="!isCurrentInvoicePosted"
												@click="checkout(true, print.receiveReport)"
											>
												<v-list-item-title>
													{{$t('print.print-receipt')}}
												</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>
								
								<tooltip top :text="$t('delete')">
									<v-btn
										v-if="!isCurrentInvoicePosted"
										height="34"
										width="34"
										min-width="34"
										depressed
										color="alert-color white--text"
										class="ms-1"
										@click="confirmDiscardDialog = true"
										:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</tooltip>
							</div>
							<!-- notes -->
							<v-text-field
								v-model="submitData.notes"
								:placeholder="$t('forms.notes')"
								dense
								outlined
								class="mb-1"
								hide-details
								:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
								:readonly="isCurrentInvoicePosted"
								:loading="loading"
							></v-text-field>

							<!-- printingNotes -->
							<v-text-field
								v-model="submitData.printingNotes"
								:label="$t('forms.printingNotes')"
								dense
								outlined
								class="mb-1"
								hide-details
								:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
								:readonly="isCurrentInvoicePosted"
								:loading="loading"
							></v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-col>
			<!-- calculated fields -->
			<v-col v-if="$vuetify.breakpoint.lgAndUp" md="2" cols="12">
				<!-- cashbox -->
				<v-autocomplete 
					v-model="submitData.cashbox" 
					:items="cashboxes" 
					:label="$t('forms.cashbox')"
					item-value="id"
					item-text="name" 
					dense 
					outlined
					:readonly="permissionsForUser.userType === userType.casher"
					:disabled="permissionsForUser.userType === userType.dataReader"
				></v-autocomplete>

				<!-- account -->
				<v-autocomplete
					v-model="submitData.account"
					:label="$t('forms.account')"
					:items="filteredAccounts"
					item-value="id"
					:item-text="(item) => `${item.accountingNo} - ${item.name}`"
					outlined
					dense
					hide-details
					class="mb-2"
					:readonly="isCurrentInvoicePosted"
					:rules="rules.required"
					:disabled="permissionsForUser.userType === userType.dataReader"
					@change="submitData.paymentMethodId !==  paymentMethod.internal && entries.length !== 0 ? alertDialog = true : null"
				></v-autocomplete>

				<!-- date -->
				<bee-date-input
					v-model="submitData.date"
					:label="$t('forms.date')"
					input-format-order="DD-MM-YYYY"
					prepend-icon="mdi-calendar"
					dense
					hide-details
					class="mb-2"
					:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
					:readonly="isCurrentInvoicePosted"
					:loading="loading"
					fit-inputs
					iso-date
				></bee-date-input>

				<v-divider class="my-2"/>
				
				<!-- payment method -->
				<div class="d-flex justify-space-between flex-wrap mb-1">
					<v-autocomplete
						v-model="submitData.paymentMethodId"
						:items="paymentMethodArray"
						item-text="name"
						item-value="id"
						:label="$t('payment-method.label')"
						outlined
						dense
						hide-details="auto"
						:disabled="permissionsForUser.userType === userType.dataReader"
						@change="submitData.paymentMethodId === paymentMethod.internal ? dialogPaymentMethod = true : null"
					/>
				</div>

				<!-- total-without-vat -->
				<v-text-field
					:label="$t('SaleInvoice.total-without-vat')"
					dense
					hide-details
					class="mb-2"
					outlined
					readonly
					:value="numberFormat(entriesTotal)"
					:disabled="permissionsForUser.userType === userType.dataReader"
				></v-text-field>

				<!-- tax -->
				<v-text-field
					:label="$t('forms.tax')"
					dense
					hide-details
					outlined
					readonly
					class="mb-2"
					:value="numberFormat(vatAmount)"
					:disabled="permissionsForUser.userType === userType.dataReader"
				></v-text-field>

				<!-- discount -->
				<v-text-field
					v-model="discountFormatted"
					v-number-format.number="[discountFormatted, submitData.discount]"
					:label="$t('forms.discount')"
					dense
					hide-details
					outlined
					class="mb-2"
					:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
					:readonly="isCurrentInvoicePosted"
					:loading="loading"
					
				></v-text-field>

				<!-- paid -->
				<v-text-field
					v-if="submitData.paymentMethodId === paymentMethod.debt"
					v-model="paidFormatted"
					v-number-format.number="[paidFormatted, submitData.paid]"
					:label="$t('forms.paid')"
					dense
					hide-details
					class="mb-2"
					outlined
					:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
					:readonly="isCurrentInvoicePosted"
					:loading="loading"
				></v-text-field>

				<v-divider/>

				<!-- total net -->
				<div class="d-flex justify-space-between align-center my-1">
					<span class="text-subtitle-2 font-weight-medium">{{$t('SaleInvoice.total-net')}}</span>
					<span class="text-subtitle-1 font-weight-bold">
						{{numberFormat(totalNet)}}
					</span>
				</div>

				<v-divider/>

				<!-- unpaid -->
				<template v-if="submitData.paymentMethodId === paymentMethod.debt">
					<div class="d-flex justify-space-between align-center edit-color--text mb-1">
						<span class="text-subtitle-2 font-weight-medium">
							{{$t('forms.unpaid')}}
						</span>
						<span class="text-subtitle-1 font-weight-bold">
							{{unpaidAmount}}
						</span>
					</div>
					<v-divider/>
				</template>

				<!-- notes -->
				<v-textarea
					v-model="submitData.notes"
					:label="$t('forms.notes')"
					dense
					outlined
					rows="1"
					class="my-2"
					hide-details
					no-resize
					auto-grow
					:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
					:readonly="isCurrentInvoicePosted"
					:loading="loading"
				></v-textarea>

				<!-- printingNotes -->
				<v-text-field
					v-model="submitData.printingNotes"
					:label="$t('forms.printingNotes')"
					dense
					outlined
					class="mb-1"
					hide-details
					:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
					:readonly="isCurrentInvoicePosted"
					:loading="loading"
				></v-text-field>

				<v-divider/>

				<!-- buttons -->
				<div class="d-flex flex-column align-center mt-2">
					<!-- print button -->
					<div class="mb-1" :style="{ width: '100%' }">
						<v-btn
							depressed
							height="40"
							width="70%"
							color="blue-dark white--text"
							class="text-capitalize pa-0"
							:class="$vuetify.rtl ? 'rounded-l-0' : 'rounded-r-0'"
							:disabled="submitLoading || loading || isCurrentInvoicePosted || totalNet < 0 || !submitData.account || !entries.length || permissionsForUser.userType === userType.dataReader"
							:loading="submitLoading || loading"
							@click="checkout(true, print.receipt)"
						>
							{{ !isCurrentInvoicePosted ? $t('check-out') : $t('checked-out')}}
						</v-btn>
						<v-menu offset-y>
							<template v-slot:activator="{ attr, on }">
								<v-btn
									small
									height="40"
									width="30%"
									min-width="30%"
									color="blue-dark white--text"
									class="print-menu-btn text-capitalize pa-0"
									:class="$vuetify.rtl ? 'rounded-r-0' : 'rounded-l-0'"
									:disabled="
										submitLoading || loading || totalNet < 0 || !submitData.account || permissionsForUser.userType === userType.dataReader
									"
									depressed
									v-on="on"
									v-bind="attr"
								>
									<v-icon size="20">mdi-chevron-down</v-icon>
								</v-btn>
							</template>

							<v-list dense>
								<v-list-item
									:disabled="isCurrentInvoicePosted || !entries.length"
									@click="checkout(false)"
								>
									<v-list-item-title>
										{{$t('print.pay-without-print')}}
									</v-list-item-title>
								</v-list-item>
								<v-list-item
									:disabled="!isCurrentInvoicePosted"
									@click="checkout(true, print.saleInvoiceReport)"
								>
									<v-list-item-title>
										{{$t('print.print-invoice')}}
									</v-list-item-title>
								</v-list-item>
								<v-list-item
									:disabled="!isCurrentInvoicePosted"
									@click="checkout(true, print.deliveryReport)"
								>
									<v-list-item-title>
										{{$t('print.print-delivery')}}
									</v-list-item-title>
								</v-list-item>
								<v-list-item
									:disabled="!isCurrentInvoicePosted"
									@click="checkout(true, print.receiveReport)"
								>
									<v-list-item-title>
										{{$t('print.print-receipt')}}
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</div>
					<v-btn
						v-if="!isCurrentInvoicePosted"
						height="28"
						width="100%"
						depressed
						color="alert-color white--text"
						class="text-capitalize"
						@click="confirmDiscardDialog = true"
						:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
					>
						{{$t('delete')}}
					</v-btn>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';
import { invoiceTypes, paymentMethod, type, userType, paymentMethodArray } from '@/helpers/enums';
import { printHtml, fixNumber } from '@/helpers/functions';
import ItemsNavigator from '@/components/ItemsNavigator.vue';
import QuantityInput from '@/components/QuantityInput.vue';
import moment from 'moment';
export default {
	name: 'SaleInvoice',
	props: {
		eventHub: { type: Object }
	},
	components: { ItemsNavigator, QuantityInput },

	data() {
		return {
			alertDialog: false,
			confirmDiscardDialog: false,

			dateAlert: {
				dialog: false,
				isDateChecked: false,
				needPrint: null,
				printType: null,
			},

			createInvoiceByTabsHandler: false,

			savedTableInputs: {},

			tableInputs: {
				quantity: 1
			},
			isEditItem: false,
			rowEditIndex: null,
			validationMessages: [],

			invoiceNo: 0,
			entries: [],
			activeItems: 1,
			originalInvoiceEntires: [],

			searchItem: null,
			isSearchByBarcode: true,

			loading: false,
			submitLoading: false,

			paidFormatted: '',
			discountFormatted: '',

			isCurrentInvoicePosted: false,

			invoiceTypes,
			paymentMethod,
			type,
			userType,
			print: {
				receipt: 1,
				saleInvoiceReport: 2,
				deliveryReport: 3,
				receiveReport: 4,
			},
			rules,

			// data
			submitData: {
				account: null,
				cashbox: null,
				date: null,
				paymentMethodId: 1,

				paid: null,
				discount: null,
				vatPercent: null,
				notes: null,
				printingNotes: null
			},
			deleteLoading: false,
			price: null,
			loadingRequestPrice: false,
			dialogPaymentMethod: false
		}
	},

	watch: {
		'$route.params.id'(val) {
			if (val) {
				this.resetTableInputs();
				this.fetchInvoice().then(() => {
					if (this.createInvoiceByTabsHandler) {
						this.createInvoiceByTabsHandler({
							id: this.$route.params.id,
							name: this.invoiceNo
						});
						this.createInvoiceByTabsHandler = false;
					}
				});
			}
		},

		'submitData.paymentMethodId'(val) {
			if (!this.isCurrentInvoicePosted) {
				if (val === paymentMethod.debt) {
					this.submitData.account = null
				} else if ([paymentMethod.cash, paymentMethod.creditCard].includes(val)) {
					this.submitData.account = this.configs.defaultCashCustomerId;
				}
			}
		},

		watchInputs() {
			if (!this.isEditItem && this.tableInputs.itemId) {
				this.savedTableInputs = this.tableInputs;
			}
		},
		
		isEditItem(val) {
			if (!val && !this.entries.find(c => c.itemId === this.savedTableInputs.itemId)) {
				this.$nextTick(() => {
					this.$refs.table.resetInputs(this.savedTableInputs)
				})
			}
		},

		watchInvoicesAndCashBox() {
			if (!this.loading) {
				this.$nextTick(() => {
					this.eventHub.$emit('update-invoices', this.invoicesDrafts)
				})
			}
		}
	},

	computed: {
		...mapState({
			configs: state => state.configs.configs,
			cashboxes: state => state.cashboxes.cashboxes,
			saleableItemsTree: state => state.items.saleableItemsTree,
			accounts: state => state.accounts.accounts,
			invoices: state => state.invoices.invoices,
			items: state => state.items.items,
			permissionsForUser: state => state.auth.permissionsForUser
		}),
		...mapGetters({
			getInvoiceById: 'invoices/getInvoiceById',
			getItemById: 'items/getItemById',
			getItemByBarcode: 'items/getItemByBarcode',
			getUnitById: 'units/getUnitById',
		}),

		// computed helpers
		contextMenuItems() {
			return [
				{ title: this.$t('edit'), icon: 'mdi-pencil', iconColor: 'edit-color', event: 'edit', class: 'edit-color--text' },
				{ title: this.$t('delete'), icon: 'mdi-delete', iconColor: 'alert-color', event: 'delete', class: 'alert-color--text' },
			]
		},

		headers() {
			const headers = [
				{
					text: this.$t('headers.serial'),
					name: 'no',
					value: 'no',
					setValueExpr: (val, index) => index + 1,
					noInput: true,
				},
				{
					text: this.$t('headers.barcode'),
					name: 'barcode',
					value: 'barcode',
					divider: true,
					noInput: true,
				},
				{
					text: this.$t('headers.item'),
					name: 'itemId',
					value: 'itemId',
					setValueExpr: val => this.items.find(c => c.id === val)?.name ?? val,
					inputRules: rules.required,
					width: 140,
					disableInput: this.isEditItem,
				},
				{
					text: this.$t('headers.quantity'),
					name: 'quantity',
					value: 'quantity',
					width: 110,
					inputRules: rules.number,
					writable: !this.isCurrentInvoicePosted,
					onWriteChange: (item, activeInput, index) => {
						this.entries[index].amount = item.quantity * item.price;
						this.entries[index].discount = item.amount * (item.percentDiscount / 100)
						this.entries[index].totalNet = item.amount - this.entries[index].discount;
					},
					setInputExpr: (inputs, activeInput) => {
						if (activeInput === 'amount') {
							return inputs.amount / inputs.price
						}
						return inputs.quantity;
					},
				},
				{
					text: this.$t('headers.price'),
					name: 'price',
					value: 'price',
					type: 'number'
				},
				{
					text: this.$t('headers.amount'),
					name: 'amount',
					value: 'amount',
					setInputExpr: (inputs, activeInput) => {
						if (activeInput === 'amount') {
							return inputs.amount;
						}
						return inputs.price * inputs.quantity;
					},
					type: 'number-format'
				},
				{
					text: this.$t('headers.percent-discount'),
					name: 'percentDiscount',
					value: 'percentDiscount',
					setInputExpr: (inputs, activeInput) => {
						if (activeInput !== 'percentDiscount') {
							let value = (inputs.discount * 100) / (inputs.amount || 1);
							const str = value.toString();
							if (str.includes('.') && str.split('.')[1].length > 2) {
								value = fixNumber(value);
							}
							return value || null
						} else {
							return inputs.percentDiscount
						}
					},
					type: 'number'
				},
				{
					text: this.$t('headers.discount'),
					name: 'discount',
					value: 'discount',
					setInputExpr: (inputs, activeInput) => {
						if (activeInput !== 'discount') {
							let value = inputs.amount * (inputs.percentDiscount / 100);
							const str = value.toString();
							if (str.includes('.') && str.split('.')[1].length > 2) {
								value = fixNumber(value);
							}
							return value || null
						} else {
							return inputs.discount
						}
					},
					type: 'number-format'
				},
				{
					text: this.$t('headers.total-net'),
					name: 'totalNet',
					value: 'totalNet',
					disableInput: true,
					setInputExpr: inputs => fixNumber(inputs.amount - inputs.discount, 2),
					type: 'number-format'
				},
			];
			if (!this.isCurrentInvoicePosted) {
				headers.splice(
					headers.length, 0,
					{
						text: '',
						name: 'actions',
						value: 'actions',
						align: 'center',
						noInput: true,
						sortable: false
					}
				)
			}
			for (let i = 0; i < headers.length; i++) {
				headers[i].columnClass = headers[i].columnClass ? headers[i].columnClass + ' px-2' : 'px-2'
			}
			return headers
		},

		filteredItems() {
			return this.items.filter(c =>
				c.type === type.sub &&
				!c.isUnsellable &&
				(this.activeItems === 1 ? c.isFavourite : true) &&
				(
					this.searchItem
						? this.isSearchByBarcode
							? c.barcode !== null ? c.barcode.includes(this.searchItem) : null
							: c.name.includes(this.searchItem)
						: true
				)
			)
		},

		filteredAccounts() {
			return this.accounts.filter(
				c => c.type === type.sub && !c.isHidden &&
				(this.submitData.paymentMethodId === paymentMethod.debt ? c.id !== this.configs.defaultCashCustomerId : true)
			)
		},

		isAccountDefaultCashCustomer() {
			return this.configs.defaultCashCustomerId === this.submitData.account
		},

		watchInputs() {
			return `${this.tableInputs.itemId} | ${this.tableInputs.quantity} | ${this.tableInputs.price} | ${this.tableInputs.discount} | ${this.tableInputs.percentDiscount}`
		},

		watchInvoicesAndCashBox() {
			return `${this.invoices.length} | ${this.submitData.cashbox} | ${this.loading}`
		},

		invoicesDrafts() {
			return this.invoices
				.filter(c => !c.isPosted && c.cashboxId === this.submitData.cashbox)
				.map(c => ({ id: c.id, name: c.no }))
		},

		// computed invoice values
		sumEntires() {
			return this.entries.map(c => c.totalNet).reduce((a, b) => a + b, 0)
		},

		entriesTotal() {
			return fixNumber(
				(this.sumEntires - this.submitData.discount) *
				(1 / (1 + (this.submitData.vatPercent / 100)))
			);
		},
		
		vatAmount() {
			return fixNumber(this.totalNet - this.entriesTotal);
		},

		totalNet() {
			return fixNumber(this.sumEntires - this.submitData.discount);
		},

		unpaidAmount() {
			return this.numberFormat(fixNumber((this.totalNet - this.submitData.paid), 3))
		},

		// helpers
		paymentMethodArray,
	},

	methods: {
		resetPrice() {
			if (this.submitData.paymentMethodId === 4) {
				for (let i = 0; i < this.entries.length; i++) {
					this.entries[i].price = 0
					this.entries[i].amount = 0
					this.entries[i].totalNet = 0
					this.entries[i].percentDiscount = 0
					this.entries[i].discount = 0
					if (this.entries[i].price !== 0) {
						this.entries[i].price = 0
						this.entries[i].amount = 0
						this.entries[i].totalNet = 0
						this.entries[i].percentDiscount = 0
						this.entries[i].discount = 0
					}
				}
				this.discountFormatted = ''
				this.submitData.discount = 0
				this.paidFormatted = 0
				this.discountTotal = 0
				this.dialogPaymentMethod = false
				return this.entries
			}
		},
		fetchSuggestedPurchasePrice() {
			if (!this.tableInputs.itemId) {
				this.tableInputs.price = null;
				return
			};

			this.loadingRequestPrice = true
			this.$store.dispatch('invoices/fetchSuggestedPurchasePrice', { 
				itemId: this.tableInputs.itemId,
				accountId: this.submitData.account 
			}).then((data) => {
				if (this.submitData.paymentMethodId === this.paymentMethod.internal) {
					this.tableInputs.price = 0	
				} else {
					if (data.isCustom) {
						this.tableInputs.price = data.price;
					} else {
						this.tableInputs.price = this.getItemById(this.tableInputs.itemId)?.salePrice?.amount;
					}
				}
			}).finally(() => {
				this.loadingRequestPrice = false;
			})
		},

		fetchSuggestedPurchasePriceForAll() {
			this.loadingRequestPrice = true
			for (let i = 0; i < this.entries.length; i++) {
				this.$store.dispatch('invoices/fetchSuggestedPurchasePrice', { 
					itemId: this.entries[i].itemId,
					accountId: this.submitData.account 
				}).then((data) => {
					if (this.submitData.paymentMethodId === this.paymentMethod.internal) {
						this.tableInputs.price = 0	
					} else {
						if (data.isCustom) {
							this.entries[i].price = data.price;
							this.entries[i].amount = this.entries[i].quantity * this.entries[i].price;
							this.entries[i].totalNet = this.entries[i].amount - this.entries[i].discount;
						} else {
							this.entries[i].price = this.getItemById(this.entries[i].itemId)?.salePrice?.amount;
						}
					}
				}).finally(() => {
					this.loadingRequestPrice = false;
					this.alertDialog = false
				})
			}
		},

		// table functions
		addFavItem(item) {
			// if (!this.entries.length) {
			// this.loadingRequestPrice = true
			// this.$store.dispatch('invoices/fetchSuggestedPurchasePrice', { 
			// 	itemId: item.id,
			// 	accountId: this.submitData.account 
			// }).then((data) => {
				
			// }).finally(() => { this.loadingRequestPrice = false })			
			// }
			const itemInTable = this.entries.find(c => c.itemId === item.id);
			if (!itemInTable) {
				this.loadingRequestPrice = true
				this.$store.dispatch('invoices/fetchSuggestedPurchasePrice', { 
					itemId: item.id,
					accountId: this.submitData.account 
				}).then((data) => {
					if (this.submitData.paymentMethodId === this.paymentMethod.internal) {
						this.tableInputs.price = 0	
					} else {
						// if (data.isCustom) {
						// 	this.tableInputs.price = data.price;
						// 	this.tableInputs.amount = this.tableInputs.quantity * this.tableInputs.price;
						// 	this.tableInputs.totalNet = this.tableInputs.amount - this.tableInputs.discount;
						// } else {
						// 	this.tableInputs.price = this.getItemById(this.tableInputs.itemId)?.salePrice?.amount;
						// }
					}
					const itemToAdd = {
						id: item.id,
						itemId: item.id,
						quantity: 1,
						price: data.isCustom ? data.price : item.salePrice?.amount ?? 1,
						amount: data.isCustom ? data.price * 1 : item.salePrice?.amount ?? 0,
						barcode: item.barcode,
						percentDiscount: 0,
						discount: 0,
						totalNet: data.isCustom ? data.price * 1 : item.salePrice?.amount ?? 0,
					}
					this.entries.push(itemToAdd)
				}).finally(() => { this.loadingRequestPrice = false })			
			} else {
				this.loadingRequestPrice = false;
				itemInTable.quantity = Number(itemInTable.quantity) + 1;
				itemInTable.amount = itemInTable.price * itemInTable.quantity;
				itemInTable.discount = itemInTable.amount * ((itemInTable.percentDiscount || 0) / 100);
				itemInTable.totalNet = itemInTable.amount - itemInTable.discount;
			}
		},

		addByBarcode() {
			const item = this.getItemByBarcode(this.searchItem);
			if (item) {
				this.addFavItem(item);
				this.searchItem = null;
			}
		},

		resetTableInputs() {
			this.$refs.table.resetInputs({
				itemId: null,
				quantity: 1,
				price: null,
				amount: null,
				percentDiscount: null,
				discount: null,
				totalNet: null,
			})
		},

		addItem() {
			if (this.$refs.table.validate()) {
				const itemInTable = this.entries.find(c => c.itemId === this.tableInputs.itemId);
				if (!itemInTable) {
					this.entries.push({
						barcode: this.getItemById(this.tableInputs.itemId)?.barcode,
						...this.tableInputs
					});
				} else {
					itemInTable.quantity = Number(itemInTable.quantity) + Number(this.tableInputs.quantity);
					itemInTable.amount = itemInTable.quantity * itemInTable.price;
					itemInTable.discount = itemInTable.amount * ((itemInTable.percentDiscount || 0) / 100);
					itemInTable.totalNet = itemInTable.amount - itemInTable.discount;
				}

				this.$nextTick(() => {
					this.resetTableInputs();
					this.$refs.table.resetInputsFocus()
				})
			}
			this.searchItem = null
		},

		editItem() {
			if (this.$refs.table.validate()) {
				this.entries[this.rowEditIndex].itemId = this.tableInputs.itemId
				this.entries[this.rowEditIndex].quantity = this.tableInputs.quantity
				this.entries[this.rowEditIndex].price = this.tableInputs.price;
				this.entries[this.rowEditIndex].amount = this.tableInputs.amount;
				this.entries[this.rowEditIndex].percentDiscount = this.tableInputs.percentDiscount;
				this.entries[this.rowEditIndex].discount = this.tableInputs.discount;
				this.entries[this.rowEditIndex].totalNet = this.tableInputs.totalNet;
				this.$refs.table.resetEdit();
				this.isEditItem = false;
				
				this.$nextTick(() => {
					this.resetTableInputs();
					this.$refs.table.resetInputsFocus()
				})
			}
		},

		deleteItem({ index }) {
			// this.resetEdit = true;
			this.$refs.table.resetEdit();
			this.isEditItem = false;
			this.entries.splice(index, 1);
			
			this.$nextTick(() => {
				this.resetTableInputs();
				this.$refs.table.resetInputsFocus()
			})
		},

		setRowIndex(rowData) {
			this.rowEditIndex = rowData.index;
			this.isEditItem = true;
			this.$nextTick(() => {
				this.$refs.table.resetInputsFocus()
			})
		},

		deleteInvoice() {
			this.deleteLoading = true
			this.$store.dispatch('invoices/delete', { id: this.$route.params.id }).then(() => {
				this.$store.dispatch('invoices/fetchAll', { type: invoiceTypes.sales }).then((data) => {
					if (this.invoicesDrafts.length === 0) {
						this.createNewInvoice(true);
					} else {
						this.$router.replace({
							name: 'sale-invoice',
							params: { id: this.invoicesDrafts[this.invoicesDrafts.length - 1].id }
						})
					}
				}).then(() => {
					this.confirmDiscardDialog = false
				}).finally(() => {
					this.deleteLoading = false
				})
			})
		},

		printReport(printType) {
			this.submitLoading = true;
			let actionName;
			switch (printType) {
			case this.print.receipt: actionName = 'invoiceReceipt'; break;
			case this.print.saleInvoiceReport: actionName = 'saleInvoice'; break;
			case this.print.deliveryReport: actionName = 'delivery'; break;
			case this.print.receiveReport: actionName = 'receive'; break;
			}

			return this.$store.dispatch(`reports/${actionName}`, {
				storeId: this.$localStorage.storeId,
				invoiceId: this.$route.params.id,
				timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
			}).then(data => {
				if (printType === this.print.receipt) {
					printHtml(data);
				}
			}).finally(() => {
				this.submitLoading = false;
			})
		},

		updateEntries(isDiscard = false, isCheckOut) {
			const currentInvoice = this.getInvoiceById(this.$route.params.id);
			const finalEntries = [];
			const areArraysEqual = (arr1, arr2, compareKeys) => {
				if (arr1.length !== arr2.length) {
					return false;
				}
				let isEqual = false;
				for (let i = 0; i < compareKeys.length; i++) {
					const key = compareKeys[i];
					isEqual = arr1.every((item, index) => item[key] === arr2[index][key])
					if (!isEqual) {
						return isEqual
					}
				}
				return isEqual
			}

			if (
				isDiscard ||
				this.isCurrentInvoicePosted ||
				areArraysEqual(this.entries, this.originalInvoiceEntires, ['itemId', 'totalNet'])
			) {
				return Promise.all([]);
			}

			this.entries.forEach((entry) => {
				const item = this.getItemById(entry.itemId);
				finalEntries.push({
					invoiceId: currentInvoice.id,
					itemId: item.id,
					quantity: {
						quantity: Number(entry.quantity),
						unitSizeId: item.unitId,
					},
					price: {
						amount: Number(entry.price),
						currencyId: this.$localStorage.currencyId
					},
					amount: {
						amount: Number(entry.amount),
						currencyId: this.$localStorage.currencyId
					},
					notes: null,
					// FIXME is should to send discountPercentage to API?
					discountPercentage: Number(entry.percentDiscount),
					discountAmount: {
						amount: Number(entry.discount),
						currencyId: this.$localStorage.currencyId
					}
				})
			})
			
			if (!isCheckOut) {
				this.$eventBus.$emit('show-snackbar', this.$t('messages.saving-the-invoice-as-a-draft'));
			}

			return this.$store.dispatch('invoices/updateEntries', {
				invoiceType: invoiceTypes.sales,
				invoiceId: currentInvoice.id,
				entries: finalEntries
			}).then(() => {
				const message =
					isCheckOut
						? this.$t('messages.saving-entries-done-wait-checkout')
						: this.$t('messages.saving-entries-done')
				this.$eventBus.$emit('show-snackbar', false, message);
			})
		},

		checkout(needPrint, printType) {
			if (!this.dateAlert.isDateChecked && moment(this.submitData.date).isBefore(moment(), 'days')) {
				this.dateAlert.needPrint = needPrint;
				this.dateAlert.printType = printType;
				this.dateAlert.dialog = true;
				this.dateAlert.isDateChecked = true;
				return;
			} else if (this.dateAlert.isDateChecked) {
				this.dateAlert.needPrint = null;
				this.dateAlert.printType = null;
				this.dateAlert.dialog = false;
				this.dateAlert.isDateChecked = false;
			};

			this.submitLoading = true;

			if (printType !== this.print.receipt && this.isCurrentInvoicePosted) {
				this.printReport(printType)
			} else if (!this.isCurrentInvoicePosted) {
				const currentInvoice = this.getInvoiceById(this.$route.params.id);
				// this.updateEntries(false, true)
				// .then(() => {
				// this.$store.dispatch('invoices/updateInvoiceWithoutEntries', {
				// 	id: this.$route.params.id,
				// 	type: invoiceTypes.sales,
				// 	cashboxId: this.submitData.cashbox,
				// 	intermediaryAccountId: this.submitData.account,
				// 	date: this.submitData.date,
				// 	notes: this.submitData.notes,
				// 	printingNotes: this.submitData.printingNotes,
				// 	refDoc: null,
				// 	paymentWay: this.submitData.paymentMethodId,
				// 	paidAmount: {
				// 		amount: this.submitData.paid,
				// 		currencyId: this.$localStorage.currencyId
				// 	},
				// 	discountTotal: {
				// 		amount: this.submitData.discount,
				// 		currencyId: this.$localStorage.currencyId
				// 	},
				// 	totalNet: {
				// 		// FIXME change name of entriesTotal computed prop
				// 		// amount: this.totalNet,
				// 		amount: this.entriesTotal,
				// 		currencyId: this.$localStorage.currencyId
				// 	},
				// 	entries: []
				// })
				const finalEntries = []
				this.entries.forEach((entry) => {
					const item = this.getItemById(entry.itemId)
					finalEntries.push({
						invoiceId: currentInvoice.id,
						itemId: item.id,
						quantity: {
							quantity: Number(entry.quantity),
							unitSizeId: item.unitId,
						},
						price: {
							amount: Number(entry.price),
							currencyId: this.$localStorage.currencyId
						},
						amount: {
							amount: Number(entry.amount),
							currencyId: this.$localStorage.currencyId
						},
						notes: null,
						// FIXME is should to send discountPercentage to API?
						discountPercentage: Number(entry.percentDiscount),
						discountAmount: {
							amount: Number(entry.discount),
							currencyId: this.$localStorage.currencyId
						}
					})
				})
				this.$store.dispatch('invoices/update', {
					id: this.$route.params.id,
					type: invoiceTypes.sales,
					cashboxId: this.submitData.cashbox,
					intermediaryAccountId: this.submitData.account,
					date: this.submitData.date,
					notes: this.submitData.notes,
					refDoc: null,
					paymentWay: this.submitData.paymentMethodId,
					paidAmount: {
						amount: this.submitData.paid,
						currencyId: this.$localStorage.currencyId
					},
					discountTotal: {
						amount: this.submitData.discount,
						currencyId: this.$localStorage.currencyId
					},
					totalNet: {
						amount: this.entriesTotal,
						currencyId: this.$localStorage.currencyId
					},
					entries: finalEntries
				})
					.then(() => {
						this.$store.dispatch('invoices/checkout', { id: currentInvoice.id })
							.then(() => {
								this.$eventBus.$emit('show-snackbar', false, this.$t('messages.checkout-done'))
								
								if (needPrint) {
									this.printReport(printType).finally(() => {
										this.submitLoading = false;
									})
								} else {
									this.submitLoading = false;
								}

								this.resetTableInputs();
								this.isCurrentInvoicePosted = true;

								const latestInvoice = this.invoices[this.invoices.length - 1];
								if (latestInvoice.id === this.$route.params.id) {
									this.createNewInvoice(false);
								} else {
									this.$store.dispatch('invoices/fetchAll', { type: invoiceTypes.sales })
										.then(() => {
											this.eventHub.$emit('update-invoices', this.invoicesDrafts)
										});
									this.fetchInvoice();
								}
							})
							.catch(() => {
								this.submitLoading = false;
							})
					})
					.catch(() => {
						this.submitLoading = false;
					})
			// })
					// .catch(() => {
					// 	this.submitLoading = false;
					// })
			}
		},

		// helpers
		numberFormat(value) {
			return this.$options.filters.numberFormat(value);
		},

		getItemFullName(item) {
			return `${item.no} - ${item.name}`	
		},

		createNewInvoice(isFirstInvoice) {
			this.$store.dispatch('invoices/create', {
				type: invoiceTypes.sales, 
				cashboxId: this.submitData.cashbox,
				intermediaryAccountId: this.configs.defaultCashCustomerId,
				date: new Date().toISOString(),
				notes: null,
				refDoc: null,
				paymentWay: this.submitData.paymentMethodId
			}).then((id) => {
				// this.fetchInvoice()
				this.$store.dispatch('invoices/fetchAll', { type: invoiceTypes.sales });
				this.$router.replace({ name: 'sale-invoice', params: { id } });
				this.submitData.date = new Date().toISOString();
				
				if (isFirstInvoice) { this.invoiceNo = 1 };
			})
		},

		fetchInvoice() {
			this.loading = true;
			return this.$store.dispatch('invoices/fetchById', { id: this.$route.params.id })
				.then((data) => {
					this.isCurrentInvoicePosted = data.isPosted;

					this.invoiceNo = data.no;
					this.submitData.date = data.date;
					this.submitData.paymentMethodId = data.paymentWay;
					this.submitData.cashbox = data.cashboxId;
					this.submitData.account = data.intermediaryAccountId;
					this.submitData.paid = data.paidAmount?.amount;
					this.paidFormatted = this.numberFormat(data.paidAmount?.amount);
					this.submitData.discount = data.discountTotal?.amount;
					this.discountFormatted = this.numberFormat(data.discountTotal?.amount);
					this.submitData.vatPercent = data.vatPercent;
					this.submitData.notes = data.notes;
					this.submitData.printingNotes = data.printingNotes;
					this.entries = [];
					data.entries.forEach((entry) => {
						this.entries.push({
							barcode: this.getItemById(entry.itemId)?.barcode,
							itemId: entry.itemId,
							quantity: entry.quantity.quantity ? entry.quantity?.quantity : 1,
							price: entry.price.amount,
							amount: entry.quantity.quantity * entry.price.amount,
							percentDiscount: entry.discountPercentage,
							discount: entry.discountAmount.amount,
							totalNet: entry.totalAmount.amount,
						})
					})
					this.originalInvoiceEntires = this.entries.map(c => ({ ...c }));
					this.tableInputs.quantity = 1
				})
				.finally(() => {
					this.loading = false;
				})
		},
		moment
	},

	created() {
		this.eventHub.$on('create-invoice', (handler) => {
			this.createInvoiceByTabsHandler = handler;
			this.createNewInvoice(false);
		})

		this.submitData.account = this.configs.defaultCashCustomerId;
		
		this.submitData.cashbox = this.permissionsForUser.userType === userType.casher ? this.$localStorage.cashboxId : this.configs.mainCashBoxId;

		// fetch last invoice / create new one
		this.loading = true;
		this.$store.dispatch('items/fetchAllAsTree', { sellableOnly: true, oneNestedLevel: true });
		if (!this.$route.params.id) {
			this.$store.dispatch('invoices/fetchAll', { type: invoiceTypes.sales })
				.then((invoices) => {
					if (invoices.length === 0) {
						this.createNewInvoice(true);
					} else {
						// casher allowed to see his cashbox invoices
						const availableInvoices =
							invoices.filter(c =>
								this.permissionsForUser.userType === userType.casher
									? c.cashboxId === this.permissionsForUser.cashboxId
									: true
							);
						const latestInvoice = availableInvoices[availableInvoices.length - 1];

						if (latestInvoice.isPosted) {
							this.createNewInvoice(false);
						} else {
							this.$router.replace({ name: 'sale-invoice', params: { id: latestInvoice.id } });
							this.submitData.date = latestInvoice.date;
							this.invoiceNo = latestInvoice.no;
							this.loading = false;
						}
					}
				})
				.catch(() => {
					this.loading = false;
				})
		} else {
			this.$store.dispatch('invoices/fetchAll', { type: invoiceTypes.sales });
			this.fetchInvoice();
		}
	},

	mounted() {
		this.tableInputs.quantity = '1';
		/*
			window.addEventListener('keydown', (e) => {
				console.log(e, e.code)
				if (e.code === 'Escape') {
					this.resetEdit = true;
				}
			})
		*/
	},
	beforeDestroy() {
		this.eventHub.$off('create-invoice');
		/*
			window.removeEventListener('keydown', (e) => {
				console.log(e, e.code)
				if (e.code === 'Escape') {
					this.resetEdit = true;
				}
			})
		*/
	},

	beforeRouteLeave(to, from, next) {
		this.updateEntries().then(() => { next(); })
	},

	beforeRouteUpdate(to, from, next) {
		this.updateEntries().then(() => { next(); })
	},

	metaInfo() {
		return {
			title: this.$t('routes.sale-invoice'),
			titleTemplate: '%s | POS'
		}
	}
}
</script>

<style lang="scss">
.table input {
	@media only screen and (min-width:960px) and (max-width:1264px) {
		font-size: 12px !important;
		font-weight: bold;
	}
}
#invoice { 
	.v-input {
		margin-top: 1px;
	}
	.bee-handy-smart-table__input-cell {
		padding: 0 8px;
	}
	#all-items-panels {
		.v-expansion-panel--active {
			> .v-expansion-panel-header  {
				min-height: 33px;
			}
		}
		.v-expansion-panel-header {
			min-height: 40px;
			padding: 0 16px;
		}
		.v-expansion-panel-content__wrap {
			padding: 0 12px;
		}
	}
	.calculated-number {
		line-height: 1.4rem;
	}
	.print-menu-btn {
		outline: 1px solid #eee;
	}
}
.w-100 {
	width: 100%
}
</style>