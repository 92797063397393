export default {
	'payment-restrictions-only': 'Payment restrictions only',
	'the-total-number': 'total count',
	'non-posted-bonds-count': 'Non posted bonds count',
	type: 'Type',
	all: 'All',
	posted: 'Posted',
	'non-posted': 'Non posted',
	tabs: {
		all: 'All',
		'cashboxes-journals': 'Cashboxes journals',
		'journals-linking-invoices': 'Journals linking invoices',
		'journals-associated-with-the-closing-of-funds': 'Journals associated with the closing of funds'
	}
}