export default {
	'payment-restrictions-only': 'Yalnızca ödeme kısıtlamaları',
	'the-total-number': 'Toplam sayı',
	'non-posted-bonds-count': 'Gönderilmeyen tahviller sayısı',
	type: 'Türü',
	all: 'Tümü',
	posted: 'Gönderildi',
	'non-posted': 'Sahneyi değiştir',
	tabs: {
		all: 'Tümü',
		'cashboxes-journals': 'Nakit yevmiye defterleri',
		'journals-linking-invoices': 'Faturaları bağlayan yevmiye defterleri',
		'journals-associated-with-the-closing-of-funds': 'Fonların kapanışıyla ilgili yevmiye defterleri'
	}
}