<template>
    <div>
		<!-- Confirm dialog import item tree into store -->
		<v-dialog v-model="confirmDialog" width="400" >
			<v-card>
				<v-card-title class="py-1">
					<v-spacer/>
					<span 
						class="ms-8 orange--text" 
					>
						{{ $t('StoreInfo.confirm-import-of-material-tree') }}  
					</span>
					<v-spacer/>
					<v-btn @click="confirmDialog = false" icon dark>
						<v-icon color="orange">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider class="mx-2" />

				<v-card-text class="py-1">
					{{ $t('StoreInfo.are-you-sure-you-want-to-import-the-material-tree-for-the-current-store?') }}
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn @click="dialogConfirmationTheSecond = true; confirmDialog = false" text color="orange">
						{{$t('yes')}}
					</v-btn>

					<v-btn text @click="confirmDialog = false">
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- confirm dialog tow import item tree into store  -->
		<v-dialog v-model="dialogConfirmationTheSecond" width="400" >
			<v-card>
				<v-card-title class="py-1">
					<v-spacer/>
					<span 
						class="ms-8 orange--text" 
					>
						{{ $t('StoreInfo.confirm-import-of-material-tree') }}  
					</span>
					<v-spacer/>
					<v-btn @click="dialogConfirmationTheSecond = false" icon dark>
						<v-icon color="orange">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider class="mx-2" />

				<v-card-text class="py-1">
					{{ $t('StoreInfo.the-material-tree-import-process-is-irreversible-and-may-take-some-time') }}
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn @click="confirmDialogInput = true; dialogConfirmationTheSecond = false" text color="orange">
						{{$t('StoreInfo.tracking')}}
					</v-btn>

					<v-btn @click="dialogConfirmationTheSecond = false" text>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- confirm dialog -->
		<v-dialog v-model="confirmDialogInput" width="400">
			<v-card>
				<v-card-title class="py-1">
					<v-spacer/>
					<span 
						class="ms-8 orange--text" 
					>
						{{ $t('StoreInfo.confirm-import-of-material-tree') }}  
					</span>
					<v-spacer/>
					<v-btn
						@click="confirmDialogInput = false"
						icon
						dark
						:disabled="loadingData"
					>
						<v-icon color="orange">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider class="mx-2" />

				<v-card-text class="pb-0 pt-2">
					<v-file-input 
						v-model="file"
						:placeholder="$t('forms.please-select-a-file')" 	
						hide-details="auto"
						outlined
						dense  
						accept=".json"
					/>
					</v-card-text>

				<v-card-actions class="pt-0">
					<v-spacer />
					<v-btn @click="importItem()" text color="orange" :loading="loadingData">
						{{$t('StoreInfo.tracking')}}
					</v-btn>

					<v-btn text @click="confirmDialogInput = false" :disabled="loadingData">
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- active / unActive dialog -->
		<v-dialog v-model="dialog" width="400px">
			<v-card>
				<v-form @submit.prevent="accountStatusChange()">
					<v-card-title class="py-1">
						<v-spacer/>
						<span
							:class="userActivatedId !== null ?  
								getUserById(userActivatedId)?.isActive ? 
								'alert-color--text' : 
							'primary--text' :
							'' "
						> 
							{{ $t('Users.edit-account-status')}}
						</span>
						<v-spacer/>
						<v-btn icon dark @click="dialog = false">
							<v-icon small :color="userActivatedId !== null ?  getUserById(userActivatedId)?.isActive ? 'alert-color' : 'primary' : '' ">
								mdi-close
							</v-icon>
						</v-btn>
					</v-card-title>
					<v-divider class="mx-4" />
					<v-card-text class="pt-3">
						{{ 
							userActivatedId !== null ? 
							getUserById(userActivatedId)?.isActive ? 
								$t('Users.are-you-sure-to-deactivate-an-account') 
								: $t('Users.are-you-sure-to-activate-an-account') 
							: '' 
						}} 
						{{
							userActivatedId !== null ? 
							getUserById(userActivatedId)?.name
							: '' 
						}}
						؟ 
					</v-card-text>

					<v-card-actions class="justify-end">
						<v-btn
							type="submit"
							:loading="loading"
							:disabled="loading"
							:color="userActivatedId !== null ? 
								getUserById(userActivatedId)?.isActive ? 
								'alert-color white--text'
								:'primary' 
							: '' "
							>
							{{$t('ok')}}
						</v-btn>
						<v-btn text @click="dialog = false" :disabled="loading"> {{$t('cancel')}} </v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- dialog -->
		<v-dialog v-model="alertDialog" width="400">
			<v-card>
				<!-- <v-card-title> -->
				<v-card-title class="py-1">
					<v-spacer/>
					<span 
						class="ms-8 primary--text" 
					>
						{{ $t('StoreInfo.alert') }}  
					</span>
					<v-spacer/>
					<v-btn
						@click="alertDialog = false"
						icon
						dark
						:disabled="loadingData"
					>
						<v-icon color="primary">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<!-- </v-card-title> -->
				<v-card-text class="pb-0">
					{{ $t('StoreInfo.your-tax-report-results-will-be-affected') }}
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn @click="alertDialog = false; basicAccount = false" color="primary">
						{{$t('ok')}}
					</v-btn>
					<v-btn @click="alertDialog = false" color="alert-color white--text">
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- add new user for manager -->
		<v-dialog v-model="dialogPower" persistent width="700px">
			<v-card>
				<v-card-title class="py-1">
					<v-spacer/>
					<span class="primary--text"> 
						{{ userId !== null ? $t('Users.user-edit') : $t('Users.user-create') }}
					</span>
					<v-spacer/>
					<v-btn
						icon
						dark
						@click="dialogPower = false"
						class="d-flex justify-end"
					>	
						<v-icon small color="primary" @click="userId = null">
							mdi-close
						</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider class="mx-4" />
				<!-- info user -->
				<v-form ref="form">
					<v-card-text class="pt-3">
						<v-row>
							<v-col cols="12" class="pb-0"> 
								<p class="mb-0">
									{{$t('Users.user-info')}}	
								</p>
							</v-col>
							<v-col>
								<v-text-field
									v-model="submitData.name"
									:label="$t('forms.name')"
									outlined
									dense
									hide-details="auto"
									:rules="rules.required"
									@change="changeBasicInfo = true" 
								/>	
							</v-col>

							<v-col>
								<v-text-field
									v-model="submitData.username"
									:label="$t('forms.user-name')"
									outlined
									dense
									hide-details="auto"
									@change="changeBasicInfo = true" 
								/>
							</v-col>
						</v-row>

						<!-- password -->
						<v-row>
							<v-col>
								<v-text-field
									v-model="submitData.password"
									:type="showPassword ? 'text' : 'password'"
									:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
									@click:append="showPassword = !showPassword"
									:label="$t('forms.password')"
									:placeholder="userId ? $t('Users.optional') : ''"
									outlined
									dense
									:rules="userId ? [] : rules.password"
									hide-details="auto"
									@change="changeBasicInfo = true" 
								/>					
							</v-col>
							<v-col>
								<!-- :rules="userId ? [] : rules.password" -->
								<v-text-field
									v-model="submitData.confirmPassword"
									:type="showConfirmPassword ? 'text' : 'password'"
									:append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
									@click:append="showConfirmPassword = !showConfirmPassword"
									:label="$t('forms.confirmPassword')"
									:placeholder="userId ? $t('Users.optional') : ''"
									outlined
									dense
									:rules="submitData.password && userId === null ? [val => val === submitData.password || $t('messages.not-matched-password')] : []"
									hide-details="auto"
									@change="changeBasicInfo = true" 
								/>
							</v-col>
						</v-row>

						<v-divider class="mt-4 mb-2"/>

						<!-- power (permations) -->
						<p>{{$t('Users.user-power')}}</p>
						<bee-handy-smart-table
							v-model="tableInput"
							ref="table"
							:headers="headersSmart"
							:items="entries"
							:items-per-page="-1"
							:show-add-row="entries.length === 0"
							stay-in-input-fields
							:loading="loadingPermissions"
							:data-table-props="{
								disableSort: true,
								noDataText: $t('entery-value-and-prass-enter')
							}"
							dense
							:editable-rows="!loadingPermissions"
							:disable-input-fields="loadingPermissions"
							@edit-cancelled="isEditItem = false"
							@keypress.enter="!isEditItem ? addItem() : editItem()"
							hide-default-footer
							@context-menu-edit="setRowIndex"
							class="mb-3"
							:validation-messages.sync="validationMessages"
						>	
							<template v-slot:item.actions="{ index }">
								<div class="d-flex">
									<!-- edit -->
									<v-btn
										text 
										small
										color="edit-color"
										:disabled="loadingPermissions"
										@click="$refs.table.activeEditForRow(index); setRowIndex({ index })"
									>
										<v-icon>mdi-pencil</v-icon>
									</v-btn>

									<!-- delete -->
									<v-btn	
										text
										small
										color="alert-color"
										:disabled="loadingPermissions"
										@click="deleteItem({ index })"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</div>
							</template>					
							<!-- storeId -->
							<template v-slot:input.storeId="{ on, attr }">
								<v-autocomplete
									:items="stores"
									item-value="id"
									item-text="name"
									auto-select-first
									v-on="on"
									v-bind="attr"
									hide-details
								></v-autocomplete>
							</template>

							<template v-slot:input.userType="{ on, attr }">
								<v-autocomplete
									:items="powersArray"
									item-text="name"
									item-value="id"
									dense
									v-on="on"
									v-bind="attr"
									hide-details
								/>
							</template>

							<template v-slot:input.cashboxId="{ on, attr }">
								<v-autocomplete
									:items="cashboxSystem.filter(c => c.storeId === tableInput.storeId)"
									item-text="name"
									item-value="id"
									dense
									hide-details="auto"
									v-on="on"
									v-bind="attr"
								/>
							</template>
						</bee-handy-smart-table>
					</v-card-text>

					<v-card-actions >
						<v-spacer />	
						<v-btn
							color="primary"
							:loading="loading"
							:disabled="loading || entries.length === 0"
							@click="submitUserAndPermission"
						> 
							{{$t('save')}}
						</v-btn>
						<v-btn
							color="alert-color white--text"
							@click="dialogPower = false"
							:disabled="loading"
						> 
							{{$t('cancel')}} 
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- dialog confirm  -->
		<v-dialog v-model="dialog">
			
		</v-dialog>

		<!-- main info -->
		<v-row>
			<v-col cols="12" sm="12" md="12" :lg="$route.query.addSub ? '12' : '8' ">
				<v-form ref="form" @submit.prevent="submit">
					<v-card elevation="2">
						<v-card-title class="py-0 font-weight-medium">
							{{$t('Item.main-info')}}
							<v-spacer/>
							<v-card-actions class="px-0">
								<div
									cols="auto"
									class=" w-100 d-flex align-items-center justify-end"
								>
									<v-btn
										:color=" $route.query.preview ? 'edit-color' : 'primary' "
										@click="$route.query.preview ? $router.replace({
											query: {	
											}
										}) :  submit() "
										:loading="loading"
										:disabled="loading || isAdmin || loadingData"
										class="white--text mx-2"
									>
										{{ $route.query.preview ? $t('edit') : $t('save') }}
									</v-btn>

									<v-btn
										v-if="$route.query.preview && !inStoreContext"
										color="blue-dark" 
										:disabled="loading || isAdmin || loadingData"
										:to="{ name: 'stores' }"
										class="white--text"
									> 
										{{ $t('back') }}
									</v-btn>

									<v-btn
										v-if="!$route.query.preview"
										color="alert-color" 
										:disabled="loading || loadingBack || isAdmin || loadingData"
										:loading="loadingBack"
										@click="
											$route.params.id ?
												getStoreById()
											:   
												$router.push({
													name: 'stores'
												})
										"
										class="white--text"
									> 
										{{ $t('cancel') }}
									</v-btn>
								</div>
							</v-card-actions>
						</v-card-title>
						<v-card-text >
							<v-row class="flex-wrap align-center">
								<v-col cols="4" md="3" class="">
									<v-col class="pt-0 border" align-self="center" sm="12" md="auto">
										<div class="d-flex justify-center align-center">
											<div class="store-image mt-3">
												<v-img
													height="110"
													max-height="110"
													width="110"
													max-width="110"
													class=""
													:src="previewPhoto || photo || require(
														'@/assets/store.png'
													)"
													:gradient="logo ? '#eee2, #eee7, #eee2' : null"
													:disabled="disableFieldsToPreview || loadingData"
												></v-img>

												<div class="d-flex">
												
													<v-file-input
														v-model="logo"
														class="ma-0 pa-0"
														:class="{ 
															
														}"
														accept=".png"
														label="employee photo"
														placeholder="Pick a photo"
														:prepend-icon="logo !== null || photo !== null ? 'mdi-image' : 'mdi-plus-circle'"
														hide-input
														@change="onImageChange"
														:disabled="disableFieldsToPreview || loadingData"
													></v-file-input>

													<v-btn
														v-if="logo !== null || photo !== null"
														icon
														width="30"
														height="30"
														min-width="0"
														color="red lighten-1"
														@click="previewPhoto = null; photo = null; logo = null"
														:disabled="disableFieldsToPreview || loadingData"
													>
														<v-icon> mdi-delete </v-icon>
													</v-btn>
												</div>
											</div>
										</div>
									</v-col>
								</v-col>
								
								<v-col cols="8" md="9" class="">
									<v-row>
										<!-- item name -->
										<v-col lg="3" md="3" sm="4" cols="6" class="pb-0">
											<v-text-field
												v-model="storeName"
												hide-details="auto"
												:label="$t('forms.name')"
												outlined
												dense
												:disabled="disableFieldsToPreview || loadingData || isAdmin"
												:loading="loading || submiteLoading"
												:rules="rules.required"
											></v-text-field>
										</v-col>

										<!-- number store -->
										<v-col lg="3" md="3" sm="4" cols="6" class="pb-0" >
											<v-text-field
												v-model.number="storeNumber"
												hide-details="auto"
												:label="$t('forms.number')"
												outlined
												dense
												:disabled="disableFieldsToPreview || loadingData || isAdmin"
												:loading="loading || submiteLoading"
												:rules="rules.requiredAsNumber"
											></v-text-field>
										</v-col>
										
										<!-- city -->
										<v-col lg="3" md="3" sm="4" cols="6" class="pb-0" >
											<v-text-field
												v-model="city"
												hide-details="auto"
												:label="$t('forms.country')"
												outlined
												dense
												:disabled="disableFieldsToPreview || loadingData || isAdmin"
												:loading="loading || submiteLoading"
											></v-text-field>
										</v-col>
										
										<!-- currency -->
										<v-col cols="6" md="3" sm="4" class="pb-0">
											<v-autocomplete
												v-model="currency"
												:label="$t('StoreInfo.currency')"
												:items="currencies"
												item-text="symbol"
												item-value="id"
												dense
												outlined
												hide-details="auto"
												:disabled="!$route.query.addSub || loadingData || isAdmin"
											/>
										</v-col>
						
										<!-- default language -->
										<v-col cols="6" md="3" sm="4" class="">
											<v-autocomplete
												v-model="defaultLanguageStore"
												:items="defaultLanguage"
												item-text="name"
												item-value="id"
												:label="$t('StoreInfo.default-language')"
												dense
												outlined
												hide-details="auto"
												:rules="rules.requiredAsNumber"
												:disabled="disableFieldsToPreview || loadingData || isAdmin"
												:loading="loading || submiteLoading"
											/>
										</v-col>

										<v-col cols="6" sm="4" md="3">
											<v-text-field
												v-model="phone"
												:disabled="disableFieldsToPreview || loadingData || isAdmin"
												:label="$t('StoreInfo.phone')"
												dense
												outlined
												hide-details="auto"
												dir="ltr"
											/>
										</v-col>

										<v-col cols="12" sm="4" md="3">
											<v-text-field
												v-model="address"
												:disabled="disableFieldsToPreview || loadingData || isAdmin"
												:label="$t('StoreInfo.address')"
												dense
												outlined
												hide-details="auto"
											/>
										</v-col>

										<v-col cols="12" sm="4" md="3">
											<v-text-field
												v-model.number="initialSaleInvoiceNumber"
												:disabled="disableFieldsToPreview || loadingData || !$route.query.addSub || isAdmin"
												:label="$t('StoreInfo.initial-number-of-sales-invoices')"
												dense
												outlined
												hide-details="auto"
												type="number"
												hide-spin-buttons
											/>
										</v-col>

										<!-- store name in English -->
										<v-col cols="6">
											<v-text-field
												v-model="nameInEnglish"
												:disabled="disableFieldsToPreview || loadingData || isAdmin"
												:label="$t('StoreInfo.store-name-in-reports-(English)')"
												dense
												outlined
												hide-details="auto"
											/>
										</v-col>

										<!-- store name in arabic -->
										<v-col cols="6">
											<v-text-field
												v-model="nameInArabic"
												:disabled="disableFieldsToPreview || loadingData || isAdmin"
												:label="$t('StoreInfo.store-name-in-reports-(Arabic)')"
												dense
												outlined
												hide-details="auto"
											/>
										</v-col>
											
										<v-col cols="12" sm="12" md="12" class="py-0">
											<v-textarea
												v-model="notes"
												outlined 
												rows="1" 
												dense
												class="mt-2"
												:label="$t('forms.notes')"
												:disabled="disableFieldsToPreview || loadingData || isAdmin"
												:loading="loading || submiteLoading"
												no-resize
											></v-textarea>
										</v-col>
									</v-row>
								</v-col>
							
								<v-col v-if="0" cols="6" sm="6" md="3" class="py-0">
									<div class="d-flex mb-7">
										<!-- :disabled="!$route.query.addSub" -->
										
									</div>
								</v-col>
								<!-- <v-col cols="">
									<v-switch
										v-model="status"
										:label="status === statusStore.activated ? $t('status-store.worked') : $t('status-store.stopped') "
										hide-details
										class="ma-0"
										:disabled="disableFieldsToPreview"
										:loading="loading || submiteLoading"
									></v-switch>
								</v-col> -->
								<v-divider v-if="!$route.query.addSub" class="mx-2" />
								<v-col v-if="!$route.query.addSub"  cols="12" class="py-0">
									{{ $t('StoreInfo.export-import-tree-item') }}
									<v-btn
										v-if="!isAdmin"
										@click="exportItem()"
										color="blue-dark white--text"
										class="mx-2 my-2"
										:loading="loadingData"
										:disabled="loading || loadingData || $route.params.id !== $localStorage.storeId || items.length === 0"
									>
										{{ $t('StoreInfo.exporting') }}
									</v-btn>
									<v-btn
										v-if="!isAdmin"
										@click="confirmDialog = true"
										color="blue-dark white--text"
										class="my-2"
										:loading="loading"
										:disabled="loading || loadingData || $route.params.id !== $localStorage.storeId || items.length !== 0"
									>
										{{ $t('StoreInfo.importing') }}
									</v-btn>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-form>
			</v-col>

			<v-col cols="12" sm="12" md="12" lg="4" v-if="!$route.query.addSub">
				<!-- permissions -->
				<v-card class="h-100" v-if="!$route.query.addSub || isAdmin">
					<v-card-title v-if="0" class="py-0 font-weight-medium">
						<p class="my-3 mx-4">{{ $t('powers') }}</p>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col v-if="permissionsForUser.userType === userType.manager" cols="12" class="pb-0 pt-1">
								<v-btn @click="dialogPower = true" color="primary"> {{ $t('add') }} </v-btn>
							</v-col>
							
							<v-col cols="12">
								<bee-handy-table
									:headers="headers"
									:items="permissionsForStores"
									:loading="loading"
									fixed-header
									:items-per-page="-1"
									:height="permissionsForUser.userType === userType.manager ? 250 : 300"
									elevation="0"
									hide-default-footer
									dense
								>
									<template v-slot:item.actions="{ item }">
										<!-- {{getUserById(item.id)?.isActive}} -->
										<div class="d-flex">
											<v-btn
												@click="userId = item.userId; dialogPower = true; permissionsUser(item.userId)"
												small
												icon
											>
												<v-icon color="edit-color"> mdi-pencil </v-icon>
											</v-btn>
											<!-- <v-switch
												v-model=""
												:value="getUserById(item.userId)?.isActive"
												:loading="loading"
												hide-details
												class="ma-0 me-4"
												dense
												:true-value="1"
												:false-value="0"
												@change="dialog = true; userActivatedId = item.userId"
											></v-switch> -->
											<!-- <v-btn
												text
												small
												color="primary"
												@click="dialog = true; userActivatedId = item.userId"
											>
												<v-icon> mdi-account-reactivate</v-icon>
											</v-btn> -->
										</div>
									</template>
								</bee-handy-table>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-card v-if="!$route.query.addSub" class="mt-2" >
			<v-card-title class="py-0 font-weight-medium"> 
				<div>
					<span>
						{{$t('default-settings')}} 
					</span>
					<span v-if="$localStorage.storeId !== $route.params.id"> 
						{{ $t('StoreInfo.please-log-in-to-the-store-context-to-add-settings')  }} 
					</span>
				</div>
				<v-spacer />
				<v-card-actions>
					<v-btn
						color="primary"
						@click="updateConfig"
						:loading="loadingConfig"
						:disabled="$localStorage.storeId !== $route.params.id || loadingData || permissionsForUser.userType === userType.dataReader"
					>
						{{$t('save')}}
					</v-btn>

					<v-btn
						color="alert-color white--text"
						:disabled="loadingConfig || $localStorage.storeId !== $route.params.id || loadingData || 	permissionsForUser.userType === userType.dataReader"
						@click="resetAllConfig"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card-title>
			
			<v-card-text class="d-flex flex-wrap justify-center">
				<div
					class=""
					v-for="(account, index) in accountConfig"
					:key="index"
					:style="$vuetify.breakpoint.smAndDown ? 'width: 30%; height: auto': 'width: 100%'"
				>
					<div
						:class="{
							'd-flex flex-column card mx-2 my-1': $vuetify.breakpoint.smAndDown, 
							'd-flex flex-row fixed-height': $vuetify.breakpoint.mdAndUp
						}"
					>
						<v-col
							sm="12"
							:md="$vuetify.breakpoint.smAndDown ? '12' : '2'"
							class="px-0 d-flex align-center justify-center success lighten-1"
							:class="{'border-radius-right' : $vuetify.breakpoint.mdAndUp && index === 0,  
								'border-radius-right border-radius-left': $vuetify.breakpoint.smAndDown,
								'border-radius-right-bottom': index === 5,
							}"
						>
							<span class="white--text"> {{account.title}} </span>
						</v-col>
						<v-col
							class="d-flex py-0"
							:md="$vuetify.breakpoint.smAndDown ? '12' : i === account.group.length -1 ? '4' : '3' "
							lg="3"
							sm="12"
							v-for="(item, i) in account.group"
							:key="i"
							:class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column' : '' "
						>
							<span
								class="d-flex align-center fs-sm w-50"
								:class="$vuetify.breakpoint.smAndDown ? 'w-100' : 'w-50' "
							>
								{{item.name}} <br> {{item.finalType}}
							</span>
							<v-autocomplete
								v-if="item.type === 'account'"
								v-model="model[index].objectOne[i]"
								:items="item.items"
								item-text="name"
								item-value="id"
								dense
								:loading="submiteLoading"
								auto-select-first
								:disabled="$localStorage.storeId !== $route.params.id || loadingData || 	permissionsForUser.userType === userType.dataReader"
								@change="getStatementAccount(item.accountStatement)"
							/>
							<v-text-field
								v-if="item.type !== 'account' && item.type !== 'string'"
								v-model.number="model[index].objectOne[i]"
								:rules="rules.numberWithZeroValue"
								prefix="%"
								class="mt-0 pt-0"
								:loading="submiteLoading"
								:disabled="$localStorage.storeId !== $route.params.id || loadingData || 	permissionsForUser.userType === userType.dataReader"
							/>

							<v-text-field
								v-if ="item.type === 'string'"
								v-model="model[index].objectOne[i]"
								:rules="rules.number"
								class="mt-0 pt-0"
								:loading="submiteLoading"
								type="number"
								hide-details
								hide-spin-buttons
								:disabled="$localStorage.storeId !== $route.params.id || loadingData || permissionsForUser.userType === userType.dataReader"
							/>
						</v-col>
					</div>
					<v-divider v-if="$vuetify.breakpoint.mdAndUp" />
				</div>
			</v-card-text>
		</v-card>
    </div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';
import { accountConfigType, finalAccount, statusStore, type, userType, defaultLanguage } from '@/helpers/enums';
export default {
	data() {
		return {
			nameInEnglish: null,
			nameInArabic: null,
			loading: false,
			loadingBack: false,
			submiteLoading: false,
			loadingData: false,
			storeName: null,
			storeNumber: null,
			city: null,
			notes: null,
			manager: null,
			status: false,
			rules,
			statusStore,
			finalAccount,
			type,
			store: [],
			exportImport: [
				{
					id: 0,
					name: this.$t('StoreInfo.import-item-tree'),
				},
				{
					id: 1,
					name: this.$t('StoreInfo.export-item-tree')
				}
			],

			// enum
			accountConfigType,
			userType,
			model: [
				{
					objectOne: [null, null]
				},
				{
					objectOne: [null, null]
				},
				{
					objectOne: [null, null, null]
				},
				{
					objectOne: [null, null, null]
				},
				{
					objectOne: [null, null, null]
				},
				{
					objectOne: [null, null, null]
				}
			],
			loadingConfig: false,
			currency: null,
			file: null,
			confirmDialog: false,
			dialogConfirmationTheSecond: false,
			defaultLanguageStore: null,
			defaultLanguage,
			confirmDialogInput: false,
			phone: '',
			address: '',
			logo: null,
			previewPhoto: null,
			photo: null,
			dialog: false,
			userActivatedId: null,
			dialogPower: false,
			userId: null,
			initialSaleInvoiceNumber: null,
			submitData: {
				name: null,
				username: null,
				password: null,
				confirmPassword: null
			},
			tableInput: {
				storeId: this.$localStorage.storeId,
				userType: userType.casher
			},
			entries: [],
			loadingPermissions: false,
			isEditItem: false,
			rowEditIndex: null,
			showPassword: false,
			showConfirmPassword: false,
			validationMessages: [],
			grantedDiscountId: null,
			alertDialog: false,
			basicAccount: true,
			changeBasicInfo: false
		}
	},
	watch: {
		'$route.query.preview'(disableFieldsToPreview) {
			if (this.$route.query.preview) { 
				disableFieldsToPreview = true
			} else { 
				disableFieldsToPreview = false
			}
		},
		dialogPower(val) {
			this.tableInput.storeId = this.$localStorage.storeId
			this.tableInput.userType = userType.casher
			if (val === true) {
				if (this.userId) {
					this.submitData.name = this.getUserById(this.userId).name
					this.submitData.username = this.getUserById(this.userId).username
					this.submitData.password = this.getUserById(this.userId).password
					// this.tableInput.storeId = this.$localStorage.storeId
					// this.tableInput.userType = userType.casher
				} else {
					this.submitData.name = null
					this.submitData.username = null
					this.submitData.password = null
					this.submitData.confirmPassword = null
					// this.tableInput.storeId = this.$localStorage.storeId
					// this.tableInput.userType = userType.casher
				}
			} else {
				this.userId = null
				this.$refs.form.resetValidation()
				this.entries = []
				this.$refs.form.reset()
			}
		},
		'$route.params.id'(val) {
			if (val) {
				this.fetchAllConfig()
				this.getStoreById()
				this.fetchAll()
			}
		}
	},
	computed: {
		disableFieldsToPreview() {
			const disableFieldsToPreview = Boolean(this.$route.query.preview)
			return disableFieldsToPreview
		},
		isAdmin () {
			return !this.auth.isAdmin
		},
		inStoreContext() {
			return this.$inStoreContext
		},
		...mapState({
			permissionsForStores: state => state.stores.permissionsForStores,
			accounts: state => state.accounts.accounts,
			configs: state => state.configs.configs,
			cashboxes: state => state.cashboxes.cashboxes,
			auth: state => state.auth.self,
			users: state => state.users.users,
			currencies: state => state.currencies.currencies,
			stores: state => state.stores.stores,
			items: state => state.items.items,
			permissionsForUser: state => state.auth.permissionsForUser,
			cashboxSystem: state => state.cashboxes.cashboxSystem
		}),
		...mapGetters({
			getUserById: 'users/getUserById',
			getCashboxById: 'cashboxes/getCashboxSystemById',
			getStorById: 'stores/getStoreById'
		}),

		accountConfig() {
			return [
				{	
					title: this.$t('StoreInfo.titles.cashboxes'),
					group: [
						{
							name: this.$t('StoreInfo.default-account-names.main-cashBoxId'),
							items: this.cashboxes,
							type: 'account',	
							accountStatement: false					
						},
						{
							name: this.$t('StoreInfo.default-account-names.account-cashboxes-reconciliation') + ' (' + this.$t('StoreInfo.types-of-final-accounts.trade') + ')',
							items: this.accounts.filter(c => c.finalType === finalAccount.trading && c.type === type.sub),
							type: 'account',
							accountStatement: false
						}
					],
				},
				{
					title: this.$t('StoreInfo.titles.intermediary-accountsId'),
					group: [
						{
							name: this.$t('StoreInfo.default-account-names.cash-customer') + ' (' + this.$t('StoreInfo.types-of-final-accounts.trade') + ')',
							items: this.accounts.filter(c => c.finalType === finalAccount.trading && c.type === type.sub),
							type: 'account',
							accountStatement: false
						},
						{
							name: this.$t('StoreInfo.default-account-names.default-resource') + ' (' + this.$t('StoreInfo.types-of-final-accounts.trade') + ')',
							items: this.accounts.filter(c => c.finalType === finalAccount.trading && c.type === type.sub),
							type: 'account',
							accountStatement: false
						}

					],
				},
				{	
					title: this.$t('StoreInfo.titles.tax'),
					group: [
						{
							name: this.$t('StoreInfo.default-account-names.vat-percent'),
							items: null,
							type: 'number',
							accountStatement: false
						},
						{
							name: this.$t('StoreInfo.default-account-names.tax-account') + ' (' + this.$t('StoreInfo.types-of-final-accounts.budget') + ')',
							items: this.accounts.filter(c => c.finalType === finalAccount.budget && c.type === type.sub),
							type: 'account',
							accountStatement: false
						},
						{
							name: this.$t('StoreInfo.default-account-names.vat-number'),
							items: null,
							type: 'string',
							accountStatement: false
						}
					],
				},
				{
					title: this.$t('StoreInfo.titles.sales'),
					group: [
						{
							name: this.$t('StoreInfo.default-account-names.sales-account') + ' (' + this.$t('StoreInfo.types-of-final-accounts.trade') + ')',
							items: this.accounts.filter(c => c.finalType === finalAccount.trading && c.type === type.sub),
							type: 'account',
							accountStatement: false
						},
						{
							name: this.$t('StoreInfo.default-account-names.sales-return-account') + ' (' + this.$t('StoreInfo.types-of-final-accounts.trade') + ')',
							items: this.accounts.filter(c => c.finalType === finalAccount.trading && c.type === type.sub),
							type: 'account',
							accountStatement: false
						},
						{
							name: this.$t('StoreInfo.default-account-names.the-discount-account-granted'),
							finalType: ' (' + 	this.$t('StoreInfo.types-of-final-accounts.trade') + ' / ' + this.$t('StoreInfo.types-of-final-accounts.gains-and-losses') + ')',
							items: this.accounts.filter(c => (c => c.finalType === finalAccount.trading && c.type === type.sub) || (c.finalType === finalAccount.profitAndLosses && c.type === type.sub)),
							type: 'account',
							accountStatement: true
						}
					],
				},
				{
					title: this.$t('StoreInfo.titles.purchase'),
					group: [
						{
							name: this.$t('StoreInfo.default-account-names.purchases-account') + ' (' + this.$t('StoreInfo.types-of-final-accounts.trade') + ')',
							items: this.accounts.filter(c => c.finalType === finalAccount.trading && c.type === type.sub),
							type: 'account',
							accountStatement: false
						},
						{
							name: this.$t('StoreInfo.default-account-names.purchases-return-account') + ' (' + this.$t('StoreInfo.types-of-final-accounts.trade') + ')',
							items: this.accounts.filter(c => c.finalType === finalAccount.trading && c.type === type.sub),
							type: 'account', 
							accountStatement: false
						},
						{
							name: this.$t('StoreInfo.default-account-names.earned-discount-account'),
							finalType: ' (' + 	this.$t('StoreInfo.types-of-final-accounts.trade') + ' / ' + this.$t('StoreInfo.types-of-final-accounts.gains-and-losses') + ')',
							items: this.accounts.filter(c => (c => c.finalType === finalAccount.trading && c.type === type.sub) || (c.finalType === finalAccount.profitAndLosses && c.type === type.sub)),
							type: 'account', 
							accountStatement: false
						}
					],
				},
				{
					title: this.$t('StoreInfo.titles.bank'),
					group: [
						{
							name: this.$t('StoreInfo.default-account-names.bank-fee-percent'),
							items: null,
							type: 'number', 
							accountStatement: false
						},
						{
							name: this.$t('StoreInfo.default-account-names.bank-account') + ' (' + this.$t('StoreInfo.types-of-final-accounts.budget') + ')',
							items: this.accounts.filter(c => c.finalType === finalAccount.budget && c.type === type.sub),
							type: 'account', 
							accountStatement: false
						},
						{
							name: this.$t('StoreInfo.default-account-names.bank-fee-account'),
							finalType: ' (' + this.$t('StoreInfo.types-of-final-accounts.trade') + ' / ' + this.$t('StoreInfo.types-of-final-accounts.gains-and-losses') + ')',
							items: this.accounts.filter(c => c.finalType === finalAccount.trading && c.type === type.sub),
							type: 'account', 
							accountStatement: false
						}
					], 
				}
			]
		},

		headers() {
			const headers = [
				{
					text: this.$t('headers.users'),
					name: 'userId',
					value: 'userId',
					setValueExpr: (val) => this.users ? this.getUserById(val)?.username : null,
					noInput: true,
					class: 'text-body-1',
					align: 'start',
					cellClass: 'text-start'
				},
				{
					text: this.$t('headers.validity-type'),
					name: 'userType',
					value: 'userType',
					setValueExpr: (val) => val === userType.casher ? this.$t('StoreInfo.casher') : this.$t('StoreInfo.manager'),
					noInput: true,
					class: 'text-body-1',
					align: 'start'
				}
			]
			if (this.permissionsForUser.userType === userType.manager) {
				headers.push(
					{
						text: '',
						name: 'actions',
						value: 'actions',
					}
				)
			}
			return headers
		},

		headersSmart() {
			const headersSmart = [
				{
					text: this.$t('Users.store-name'),
					name: 'storeId',
					value: 'storeId',
					setValueExpr: val => this.getStorById(val)?.name,
					class: 'text-caption'
				},
				{
					text: this.$t('Users.power-type'),
					name: 'userType',
					value: 'userType',
					setValueExpr: val => val === userType.casher ? this.$t('Users.casher') : this.$t('Users.executive-director'),
					// inputRules: this.tableInput.storeId && this.tableInput.userType === null ? rules.required : [],
					class: 'text-caption'
				},
				{
					text: this.$t('forms.cashbox'),
					name: 'cashboxId',
					value: 'cashboxId',
					setValueExpr: val => this.getCashboxById(val)?.name,
					class: 'text-caption'
				},
				{
					text: '',
					name: 'actions',
					value: 'actions',
					noInput: true,
				}
			]
			return headersSmart
		},

		powersArray() {
			const powersArray = [
				{
					name: this.$t('Users.casher'),
					id: userType.casher
				}, 
				// {
				// 	name: this.$t('Users.executive-director'),
				// 	id: userType.manager
				// },
				// {
				// 	name: this.$t('Users.viewer'),
				// 	id: userType.dataReader
				// }
			]
			return powersArray
		}
	},
	methods: {
		submit() {
			if (this.$refs.form.validate()) {
				this.loading = true
				const actionName = this.$route.params.id ? 'update' : 'create'
				this.$store.dispatch(`stores/${actionName}`, {
					id: this.$route.params.id,
					name: this.storeName,
					storeNo: this.storeNumber,
					country: this.city,
					defaultCurrencyId: this.currency,
					defaultLanguage: this.defaultLanguageStore, 
					phone: this.phone,
					logo: this.logo,
					address: this.address,
					initialSaleInvoiceNumber: this.initialSaleInvoiceNumber,
					anotherName: this.nameInArabic,
					originName: this.nameInEnglish,
					notes: this.notes
				}).then((data, disableFieldsToPreview) => {
					if (actionName === 'create') {
						this.$store.dispatch('stores/fetchAll', {})	
						this.$store.dispatch('stores/StoreAccessPermissions', { 
							storeId: data
						})
						this.$localStorage.storeId = data;
						window.axios.defaults.headers.common['x-store'] = data;
						this.$store.dispatch('reset');

						this.$emit('update-main-data');

						// if (this.$route.name !== 'home') {
						this.$router.replace({ name: 'home' });
						// }
					} else {
						this.$router.replace({
							name: 'store-info',
							params: {
								id: actionName === 'create' ? data : this.$route.params.id,
							},
							query: {
								preview: 1 
							}
						})
					}
					disableFieldsToPreview = true
				}).finally(() => {
					this.loading = false
				})
			}
		},
		fetchAllConfig() {
			return this.$store.dispatch('configs/fetchById', {
				storeId: this.$route.params.id ? this.$route.params.id : this.$localStorage.storeId
			}).then((data) => {
				this.model[0].objectOne[0] = data.mainCashBoxId
				this.model[0].objectOne[1] = data.cashboxesReconciliationId

				this.model[1].objectOne[0] = data.defaultCashCustomerId
				this.model[1].objectOne[1] = data.defaultImporterId

				this.model[2].objectOne[0] = data.vatPercent === null ? 0 : data.vatPercent
				this.model[2].objectOne[1] = data.vatAccountId
				this.model[2].objectOne[2] = data.trn

				this.model[3].objectOne[0] = data.salesAccountId
				this.model[3].objectOne[1] = data.salesRefundAccountId
				this.model[3].objectOne[2] = data.grantedDiscountId
				this.grantedDiscountId = data.grantedDiscountId

				this.model[4].objectOne[0] = data.purchasesAccountId
				this.model[4].objectOne[1] = data.purchasesRefundAccountId
				this.model[4].objectOne[2] = data.earnedDiscountId

				this.model[5].objectOne[0] = data.bankFeePercent === null ? 0 : data.bankFeePercent
				this.model[5].objectOne[1] = data.bankAccountId
				this.model[5].objectOne[2] = data.bankFeeAccountId
				this.initialSaleInvoiceNumber = data.initialSaleInvoiceNumber
			})
		},

		updateConfig() {
			this.loadingConfig = true
			this.$store.dispatch('configs/updateConfig', {
				mainCashBoxId: this.model[0].objectOne[0],
				cashboxesReconciliationId: this.model[0].objectOne[1],

				defaultCashCustomerId: this.model[1].objectOne[0],
				defaultImporterId: this.model[1].objectOne[1],

				vatPercent: this.model[2].objectOne[0] === 0 ? null : this.model[2].objectOne[0],
				vatAccountId: this.model[2].objectOne[1],
				trn: this.model[2].objectOne[2],

				salesAccountId: this.model[3].objectOne[0],
				salesRefundAccountId: this.model[3].objectOne[1],
				grantedDiscountId: this.model[3].objectOne[2],

				purchasesAccountId: this.model[4].objectOne[0],
				purchasesRefundAccountId: this.model[4].objectOne[1],
				earnedDiscountId: this.model[4].objectOne[2],

				bankFeePercent: this.model[5].objectOne[0] === 0 ? null : this.model[5].objectOne[0], 
				bankAccountId: this.model[5].objectOne[1],
				bankFeeAccountId: this.model[5].objectOne[2]
			}).then(() => {
				this.fetchAllConfig().then(() => {
					this.loadingConfig = false
				})
			}).finally(() => {
				// this.loadingConfig = false
				this.basicAccount = true
			})
		},
		removeAccount(index) {
			// if (this.items[index].type === 'account') {
			// 	this.disabled = true
			// 	this.$store.dispatch('configs/removeAccount', {
			// 		type: this.indexItem,
			// 		accountId: this.modelName[index]
			// 	}).then(() => {
			// 		this.modelName[index] = null;
			// 		this.fetchAllConfig()
			// 	}).finally(() => {
			// 		this.indexRow = null;
			// 		this.reset = 0
			// 		this.disabled = false
			// 	})
			// }
		},
		getStoreById() {
			this.loading = true
			this.$store.dispatch('stores/fetchById', {
				id: this.$route.params.id ? this.$route.params.id : this.$localStorage.storeId
			}).then((data) => {
				this.storeName = data.name
				this.storeNumber = data.storeNo
				this.city = data.country
				this.defaultCurrencyId = data.defaultCurrencyId
				this.defaultLanguageStore = data.defaultLanguage
				this.phone = data.phone
				this.notes = data.notes
				this.status = data.status
				this.currency = data.defaultCurrencyId
				this.address = data.address
				// this.logo = data.logo
				this.photo = data.logo
				this.nameInEnglish = data.originName   
				this.nameInArabic = data.anotherName
			}).finally(() => {
				this.loading = false
			})
		},
		resetAllConfig() {
			this.model[0].objectOne[0] = null;
			this.model[0].objectOne[1] = null;

			this.model[1].objectOne[0] = null;
			this.model[1].objectOne[1] = null;

			this.model[2].objectOne[0] = null;
			this.model[2].objectOne[1] = null;
			this.model[2].objectOne[2] = null;

			this.model[3].objectOne[0] = null;
			this.model[3].objectOne[1] = null;
			this.model[3].objectOne[2] = null;
			this.grantedDiscountId = null;

			this.model[4].objectOne[0] = null;
			this.model[4].objectOne[1] = null;
			this.model[4].objectOne[2] = null;

			this.model[5].objectOne[0] = null; 
			this.model[5].objectOne[1] = null;
			this.model[5].objectOne[2] = null
		},
		importItem () {
			this.loadingData = true
			this.$store.dispatch('items/importItemTree', { file: this.file }).then(() => {
				this.$store.dispatch('items/fetchAll')
				this.$eventBus.$emit('show-snackbar', false, this.$t('StoreInfo.the-import-process-was-successful'))
			}).finally(() => {
				this.confirmDialogInput = false
				this.loadingData = false
			})
		},

		exportItem () {
			this.loadingData = true
			this.$store.dispatch('items/exportItemTree').then(() => {
				this.$eventBus.$emit('show-snackbar', false, this.$t('StoreInfo.the-store-is-materials-file-has-been-successfully-exported-to-the-Downloads-folder'))
			}).finally(() => {
				this.loadingData = false
			})
		},

		onImageChange() {
			if (this.logo) {
				const reader = new FileReader();
				reader.readAsDataURL(this.logo);
				reader.onload = e => { this.previewPhoto = e.target.result || this.logo; }
			}
		},

		accountStatusChange() {
			this.loading = true
			const actionName = this.getUserById(this.userActivatedId)?.isActive === true ? 'deactivate' : 'activate'
			this.$store.dispatch(`users/${actionName}`, { id: this.userActivatedId }).finally(() => {
				this.fetchAll()
				this.$store.dispatch('users/fetchAll')
				this.loading = false
				this.dialog = false
			})
		},

		addItem() {
			if (this.$refs.table.validate()) {
				this.entries.push({
					...this.tableInput
				})
				this.$nextTick(() => {
					this.resetTableInputs();
					// this.$refs.table.resetInputsFocus();
				})
			}
		},

		editItem() {
			// // if (this.$refs.table.validate()) {
			// this.entries[this.rowEditIndex].storeId = this.tableInput.storeId
			// this.entries[this.rowEditIndex].userType = this.tableInput.userType
			// this.entries[this.rowEditIndex].cashboxId = this.tableInput.cashboxId;
			// this.$refs.table.resetEdit();
			// this.isEditItem = false;
			
			// this.$nextTick(() => {
			// 	this.resetTableInputs();
			// 	this.$refs.table.resetInputsFocus();
			// })
			// // }

			if (this.$refs.table.validate()) {
				this.entries[this.rowEditIndex].storeId = this.tableInput.storeId
				this.entries[this.rowEditIndex].userType = this.tableInput.userType
				this.entries[this.rowEditIndex].cashboxId = this.tableInput.cashboxId;
				this.isEditItem = false;
				
				this.$nextTick(() => {
					this.$refs.table.resetEdit();
					this.resetTableInputs();
					this.$refs.table.resetInputsFocus();
				})
			}
		},

		resetTableInputs() {
			this.$refs.table.resetInputs({
				storeId: this.$localStorage.storeId,
				userType: this.userType.casher,
				cashboxId: null
			})
		},

		setRowIndex(rowData) {
			this.rowEditIndex = rowData.index;
			this.isEditItem = true;
			this.$nextTick(() => {
				this.$refs.table.resetInputsFocus();
			})
		},

		addPermission(id) {
			return this.$store.dispatch('users/createPermissionsByManager', { 
				id: id,
				// entryPermissions: this.entries[0]/
				storeId: this.entries[0].storeId,
				cashboxId: this.entries[0].cashboxId, 
				userType: this.entries[0].userType
			}).then(() => {
				this.fetchAll()
			}).catch((e) => {
				this.message = e.response.data.message;
				this.$eventBus.$emit('show-snackbar', true, this.message);
			})
		},

		submitUserAndPermission() {
			const actionName = this.userId !== null ? 'update' : 'create';
			if (this.$refs.form.validate()) {
				this.loading = true
				// Promise.all([
				if (actionName === 'create' || this.changeBasicInfo) {
					console.log('ji')
					this.$store.dispatch(`users/${actionName}`, {
						id: this.userId ? this.userId : null, 
						name: this.submitData.name,
						username: this.submitData.username, 
						password: this.submitData.password, 
						confirmPassword: this.submitData.confirmPassword
					}).then((data) => {
						this.$eventBus.$emit('show-snackbar', false, actionName === 'create' ? this.$t('Users.the-user-has-been-added-successfully') : this.$t('Users.user-data-has-been-modified-successfully'));

						if (actionName === 'create') {
							this.addPermission(data)
						} else {
							this.addPermission(this.userId)
						}
						this.dialogPower = false
						// this.entries = []
					}).catch((e) => {
						this.message = e.response.data.message;
						this.$eventBus.$emit('show-snackbar', true, this.message);
					}).finally(() => {
						this.loading = false
						this.$refs.table.resetInputs()
						this.entries = []
					})
				} else {
					console.log('3')
					this.addPermission(this.userId)
					this.dialogPower = false
				}
			}
		}, 

		deleteItem({ index }) {
			if (this.userId) {
				this.$refs.table.resetEdit();
				this.isEditItem = false;
				// cancelPermissionsByManger
				this.$store.dispatch('users/cancelPermissionsByManger', { 
					id: this.userId, 
					storeId: this.$localStorage.storeId 
				}).then(() => {
					this.entries.splice(index, 1);
					this.$nextTick(() => {
						this.resetTableInputs();
						this.$refs.table.resetInputsFocus();
					})
				})
			} else {
				this.entries = []
			}
		},

		permissionsUser(userId) {
			this.loadingPermissions = true;
			this.entries = []
			this.$store.dispatch('stores/fetchAll', {})
			
			this.$store.dispatch('users/permissionsUser', { 
				id: userId
			}).then((data) => {
				for (let i = 0; i < data.length; i++) {
					this.entries.push({
						storeId: data[i].storeId,
						cashboxId: data[i].cashboxId,
						userType: data[i].userType
					})
				}
			}).finally(() => {
				this.loadingPermissions = false
			})
		},

		getStatementAccount(accountStatement) {
			if (accountStatement === true && this.basicAccount === true) {
				this.$store.dispatch('statisticsReports/getAccountStatement', { 
					accountId: this.grantedDiscountId
				}).then((data) => {
					if (data.summary.sumDifference.amount === 0) {
						this.grantedDiscountId = this.model[3].objectOne[2]
					} else {
						this.alertDialog = true
						this.model[3].objectOne[2] = this.grantedDiscountId
					}
				})
			}			
		},

		fetchAll () {
			this.loading = true
			this.$store.dispatch('stores/StoreAccessPermissions', { 
				storeId: this.$route.params.id ? this.$route.params.id : this.$localStorage.storeId 
			}).then((data) => {
				this.entries = []
				// this.entries = data
			})
			this.$store.dispatch('users/fetchAll').finally(() => {
				this.loading = false
			})
		},
	},

	created() {
		if (this.currencies.length === 0) {
			this.$store.dispatch('currencies/fetchAll')
		}
		if (!this.$route.query.addSub) {
			this.getStoreById()
			this.$store.dispatch('stores/StoreAccessPermissions', { 
				storeId: this.$route.params.id ? this.$route.params.id : this.$localStorage.storeId 
			})
		}
		
		if (this.$inStoreContext) {
			this.submiteLoading = true;
			this.loadingConfig = true;
			Promise.all([
				this.$store.dispatch('items/fetchAll'),
				this.$store.dispatch('cashboxes/fetchAll'),
				// this.$store.dispatch('currencies/fetchAll'),
				this.$store.dispatch('cashboxes/fetchCashboxSystem')
			]).finally(() => {
				this.submiteLoading = false
				this.loadingConfig = false
			})

			if (!this.$route.query.addSub) {
				this.fetchAllConfig().then(() => {
					this.$store.dispatch('accounts/fetchAll')
				})
			}
		}
		if (this.users.length === 0) {
			this.$store.dispatch('users/fetchAll')
		}
	},

	metaInfo() {
		return {
			title: this.$t('routes.store-info'),
			titleTemplate: '%s | POS'	
		}
	}
}
</script>
<style scoped lang="scss">
.border-radius-right{
	border-top-right-radius: 8px;
}
.border-radius-left{
	border-top-left-radius: 8px;
}
.card {
	border: 1px solid rgb(235, 235, 235);
	border-radius: 8px;
}
.border-radius-right-bottom {
	border-bottom-right-radius: 8px;
}
.w-50 {
	width: 50%
}
.w-100 {
	width: 100%
}
.h-100 {
	height: 100%
}
.fs-sm {
	font-size: 12px;
}
.fixed-height {
	height: 45px;
	@media only screen and (max-width: 1275px) {
		height: 60px;
	}
	@media only screen and (max-width: 1100px) {
		height: auto;
	}
}
.store-image {
    position: relative;

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 38%;
        right: 38%;
        left: 38%;
    }
}

.border {
	border: 1px #9e9e9e solid; border-radius: 6%;
	margin-bottom: 1.8rem;
	margin-top: 1rem;
}
</style>