export default {
	info: 'Info',
	'select-store': 'Select store',
	menu: {
		logout: 'logout'
	},
	'are-you-sure-you-want-to-logout': 'Are you sure you want to logout?',
	pages: {
		stores: 'Stores',
		'store-info': 'Store information',
		'store-cashboxes': 'Store cashboxes',
		units: 'Units',
		users: 'Users',
	},
	
}