export default {
	'select-item': 'Select item please',

	'the-item': 'the item?',

	'main-info': 'Main Info.',
	'item-name': 'Item name',
	'main-item': 'Main item',
	'item-no-automatic-or-manual': 'Item no (auto or manual)',

	'item-type': 'Item type',

	'item-classification': 'Item classification',
	unit: 'unit',

	'trading-info': 'Trading Info.',
	'selling-price': 'Selling price',
	min: 'Min',
	max: 'Max',
	manufacturer: 'Manufacturer',
	notes: 'notes',
	'favorite-item': 'Favorite item',
	unsaleable: 'Unsaleable',
	'the-cost-of-the-input-items': ':The cost of the input items',
	// 'ingredients': 'ingredients'
	ingredients: 'Ingredients',
	'the-difference': 'The difference',
	'for-one-of-$-from-$': 'for one of {unit} from {item}',
	'the-component-has-been-modified-successfully': 'The component has been modified successfully',
	'mixture-ingredient': 'Mixture ingredient',
	'purchasing-price': 'Purchasing price',
	'add-ingredient-after-save-item': 'Add ingredient after save item',
	'this-item-has-been-added-before-you-can-edit-the-item-instead': ''
}