export default {
	'fiscal-account': 'Mali hesap',
	users: 'Kullanıcılar',

	'tax-percent': 'KDV yüzdesi',
	cashbox: 'Nakit',
	cashboxes: 'Nakitler',
	delete: 'Sil ',
	active: 'Aktif',
	inactive: 'Aktif değil',
}