export default {
	info: 'Bilgi',
	'select-store': 'Depo Seçin',
	'are-you-sure-you-want-to-logout': 'Oturumu kapatmak istediğinizden emin misiniz?',
	menu: {
		logout: 'Çıkış'
	},
	pages: {
		stores: 'Depo',
		'store-info': 'Depo bilgisi',
		'store-cashboxes': 'Depo kasaları',
		units: 'Birimler',
		users: 'Kullanıcılar',
	}
}