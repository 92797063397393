export default {
	'select-account': 'Lütfen hesap seçin',

	'the-account': 'hesap?',

	'main-info': 'Ana Bilgi',
	'account-name': 'Hesap Adı',
	'main-account': 'Ana Hesap',
	'account-no-automatic-or-manual': 'Hesap Numarası (otomatik ya da manuel)',

	'account-type': 'Hesap Türü',

	'final-account': 'Son Hesap',
	'is-hidden': 'Gizli Hesap',
	notes: 'Notlar',

	'info-account': {
		'phone-number': 'Telefon Numarası',
		'tax-number': 'Vergi Numarası',
		address: 'Adres',
		email: 'E-posta',
		'mobile-number': 'Cep Telefonu Numarası'
	},

	tabs: {
		info: 'Bilgilendirme',
		'custom-prices': 'Özel Fiyatlar'
	}
}