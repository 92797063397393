export default {
	opened: 'Açılış',
	closed: 'Kapanış',
	store: 'Depo',
	worked: 'Çalışma',
	stopped: 'Durdurulmuş',
	'power-type': 'Güç tipi',
	'archive-store': 'Arşiv deposu',
	'unarchive-a-store': 'Bir deponun arşivini açma',
	'are-you-sure-you-want-to-archive-this-store': 'Bu depoyu arşivlemek istediğinizden emin misiniz?',
	'are-you-sure-you-want-to-unarchive-this-store': 'Bu depoyu arşivden çıkarmak istediğinizden emin misiniz?',
	'the-store-has-been-successfully-archived': 'Depo başarıyla arşivlendi',	
	'the-store-has-been-successfully-unarchived': 'Depo başarıyla arşivden çıkarıldı',
	'your-tax-report-results-will-be-affected': 'Vergi raporu sonuçlarınız etkilenecektir',
	alert: 'uyarı'
}